<template>
  <div id="app">
    <Header></Header>
    <!-- <AuthenModal /> -->
    <router-view class="page-wrapper" />
    <!-- 部分路由不应该包含这个Footer -->
    <Footer v-if="excludeRoutes.indexOf($route.name) == -1"></Footer>
    <Im ref="im"></Im>
  </div>
</template>

<script>
  import Header from "@/components/header/Header"
  import Footer from "@/components/footer/Footer"
  import AuthenModal from "@/components/authentication/AuthenModal"
  import Im from '@/components/im/Im'
  export default {
    name: "App",
    beforeRouteEnter(to, from, next) {
      window.scrollTo(0, 0)
      next()
    },
    data() {
      return {
        excludeRoutes: [
          "HomeIndex",
          "MyAddress",
          "AddAddress",
          "MyOrder",
          "MyShoppingCart",
        ],
      };
    },
    components: {
      Im,
      Header,
      Footer,
      AuthenModal
    },
    created(){
      this.pcWebConfig();

      if (this.$store.state.token) {
        this.$store.dispatch('getUserInfo')
      }
    },
    methods:{
      //获取网站配置信息
      pcWebConfig(){
        this.$http.pcWebConfig().then(res=>{
          if(res.status == 200){
            document.title = res.data.webtitle + ' ' + res.data.description
            localStorage.setItem("webConfig", JSON.stringify(res.data));
          }
        })
      },

      showIm (user) {
        this.$refs.im.show(user)
      }
    }
  }
</script>

<style lang="scss">
@import "./common/style/reset.css";
@import "@icon-park/vue/styles/index.css";
@import "@/common/style/common.scss";
#app {
  padding-top: 40px;
}

body {
  background-color: #f9f9f9!important;
}

.page-wrapper {
  min-height: calc(100vh - 260px);
}
.w {
  width: 1190px;
  margin: 0 auto;
}
::v-deep .ivu-input:hover{
  border:none
}
.flex{
  @include flex()
}
.ivu-checkbox-inner{
  border-radius: 100px;
}
.ivu-checkbox-checked .ivu-checkbox-inner{
  border: 1px solid #e2231a;
  background-color: #e2231a;
}
.mt-20{
  margin-top: 20px;
}
.ivu-btn-error{
  background-color:#e2231a;
  height: 50px;
}
input:-internal-autofill-selected {
  background-color: transparent !important;;
}
input:-webkit-autofill {
  transition: background-color 2000s;
}
::v-deep .ivu-input:focus{
  border-color: transparent!important;
}
::v-deep .ivu-input:hover{
  border-color: transparent!important;
}
::v-deep .ivu-form-item-error .ivu-input:focus{
  border: none;
  box-shadow: none;
}
::v-deep .ivu-input:hover{
  border: none;
}
::v-deep .ivu-input-group-append{
  border: none;
}
::v-deep .ivu-btn:focus{
  box-shadow:none;
}
</style>
