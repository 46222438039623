<template>
  <Modal
    v-model="modalShow"
    :title="$t('添加收货地址')"
    footer-hide
    width="800"
  >
      <div class="name-wrap">
        <Input class="address-input" v-model="name" :placeholder="$t('收货人')" />
        <Input class="address-input" v-model="phone" :placeholder="$t('请输入您的手机号')" />
      </div>
      <Input
        class="address-input"
        v-model="address"
        :placeholder="$t('请输入省/市/区')"
      />
      <Input
        class="address-input address-input-detail"
        v-model="detailAddress"
        :placeholder="$t('详细地址')"
        type="textarea"
      />
      <div @click="setDefault">
      <Radio v-model="isDefault" >{{$t('设为默认')}}</Radio>

      </div>

    <div class="modal-bottom">
      <Button @click="onCancel">{{$t('取消')}}</Button>
        <Button type="error" @click="onConfirm">{{$t('提交')}}</Button>
    </div>
  </Modal>
</template>
<script>
export default {
  name: "AddAddress",
  data() {
    return {
      modalShow: false,
      name: "",
      phone: "",
      address: "",
      detailAddress: "",
      isDefault: false,
    };
  },
  methods: {
    handleRender() {
      this.modalShow = true;
    },
    setDefault(){
      this.isDefault = !this.isDefault
    },
    onConfirm(){
      const {  name,
        phone,
        address,
        detailAddress,isDefault} = this
      if(!name){
         return  this.$Message.error(this.$t('请填写收货人姓名'))
      }
      if(!phone){
        return  this.$Message.error(this.$t('请输入手机号'))
      }
      if(!/^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/.test(phone)){
        return  this.$Message.error(this.$t('请输入正确的手机号'))
      }
      if(!address){
        return  this.$Message.error(this.$t('请先添加收货地址'))
      }
      if(!detailAddress){
        return  this.$Message.error(this.$t('请填写详细地址'))
      }
      this.$emit('onConfirm',{
        name,
        phone,
        address,
        detailAddress,isDefault})
    },

    onCancel(){
      this.reset()
      this.modalShow = false;
    },
    reset(){
      this.name = '',
        this.phone=''
      this.address=''
      this.detailAddress=''
      this.isDefault= false

    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/common/style/common.scss";
.name-wrap {
  @include flex($jc: space-between);
  .address-input {
    width: 48%;
  }
}
.address-input {
  margin: 10px 0;
  ::v-deep .ivu-input {
    height: 40px;
  }
  &-detail{
    ::v-deep .ivu-input {
      height: 110px;
    }
  }
}
::v-deep .ivu-modal-header-inner{
  border:none;
  margin-top: 10px;
}
::v-deep .ivu-modal-footer {
  .ivu-btn-primary {
    background-color: #e2231a;
    border: none;
    border-radius: 0;
  }
}
.modal-bottom{
    text-align:right;
  margin-top: 20px;
}
</style>
