<template>
  <div class="coupon-wrap">
    <Title arrowType="'round'" url="/productGoods/goodsList?tag=recommend">
      <template #title>
        <h1 class="title">
          <span class="title-end">{{$t('推荐单品')}}</span>
        </h1>
      </template>
    </Title>
    <div>
      <Carousel autoplay loop dots="none">
        <CarouselItem v-for="(item, i) in goodsList" :key="`single-${i}`" >
          <div class="single-item" @click="goGoodsDeatil(item.id)">
            <img
              class="single-item-coverImg"
              :src="item.thumb_url"
              :alt="item.goods_name"
            />
            <p class="single-item-title">{{ item.goods_name }}</p>
            <p class="single-item-price">{{ item.currency_symbol }}{{ item.zs_price }}</p>
          </div>
        </CarouselItem>
      </Carousel>
    </div>
  </div>
</template>
<script>
import Title from "../Title";
import {goGoodsDeatil} from "@/common/utils"
export default {
  name: "RecommentSingle",
  props:{
    goodsList: {
      type:Array,
    default:()=>[],
    required:true}
  },
  components: {
    Title,
  },
  methods: {
    goGoodsDeatil
  }
};
</script>
<style lang="scss" scoped>
@import "@/common/style/common.scss";
.coupon {
  &-wrap {
    @extend .common-wrap;
    padding: 10px 20px 0;
    height: 350px;
  }
  &-items {
    display: flex;
    &-li {
      display: flex;
      align-items: center;
      flex-direction: column;
      
      &-bottom {
        padding: 10px 5px 20px;
        &-title {
          margin-bottom: 6px;
        }
      }
    }
    &-coverImg {
      width: 100%;
      height: 185px;
    }
  }
}
.title {
  font-size: 0;
  & > span {
    font-size: 24px;
    font-weight: 800;
  }
  &-front {
    color: #333;
  }
  &-end {
    color: #e2231a;
  }
}

.single-item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
  &-title {
    @include lineClamp(1);
    padding: 0 40px;
    margin-top: 14px;
  }
  &-coverImg {
    width: 180px;
    height: 185px;
  }
  &-price {
    font-size: 18px;
    color: #e2231a;
    font-weight: bold;
  }
}
</style>
