<template>
  <div class="shop-card">
    <Avatar icon="ios-person" :src="shopInfo.logo" size="large" />
    <div>
      <h4 class="shop-name">{{ shopInfo.shop_name }}</h4>
      <p class="shop-desc">{{ shopInfo.shop_desc }}</p>
    </div>
    <div>
      <p class="shop-rate">
        <span class="left-name">{{ `${$t("店铺服务")}:` }}</span>
        <Rate v-model="shopInfo.fw_fen" />
      </p>
      <p class="shop-rate">
        <span class="left-name">{{ `${$t("商品评分")}:` }}</span>
        <Rate v-model="shopInfo.goods_fen" />
      </p>
      <p class="shop-rate">
        <span class="left-name">{{ `${$t("物流评分")}:` }}</span>
        <Rate v-model="shopInfo.wuliu_fen" />
      </p>
    </div>
    <div>
      <span class="shop-btn" @click="$utils.goMerchant(shopInfo.id)">{{
        $t("进店逛逛")
      }}</span>
      <span class="shop-btn" @click="focus(shopInfo.coll_shops)" v-if="shopInfo.coll_shops==1">{{ $t("已关注") }}</span>
      <span class="shop-btn" @click="focus(shopInfo.coll_shops)" v-else>{{ $t("关注") }}</span>
      <span class="shop-btn" @click="toService">{{ $t("联系客服") }}</span>
    </div>

    <div class="shop-goodsList">
      <div
        class="shop-goodsList-item"
        v-for="(item, i) in shopInfo && shopInfo.goods_list" :key="i"
        @click="$utils.goGoodsDeatil(item.id)"
      >
        <img class="shop-goodsList-cover" :src="item.thumb_url" />
        <p class="shop-goodsList-name">
          {{ item.goods_name }}
        </p>
        <p class="shop-goodsList-bottom">
          <span>{{ item.currency_symbol }}{{ item.shop_price }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShopCard",
  props: {
    shopInfo: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  methods: {
    goShop() {},
    focus(coll_shops) {
      this.$emit('focus',coll_shops);
    },

    toService () {
      this.$emit('toService')
    }
  },
};
</script>

<style lang="scss" scoped>
.shop {
  &-card {
    border: 1px solid #eee;
    padding: 20px 10px;
    width: 204px;
    margin-left: 20px;
    text-align: center;
  }
  &-name {
    font-size: 14px;
    margin: 10px 0 2px;
  }
  &-desc {
    font-size: 12px;
    color: #999;
    margin-bottom: 10px;
  }
  &-btn {
    font-size: 12px;
    border: 1px solid #999;
    padding: 3px 6px;
    border-radius: 2px;
    margin-top: 10px;
    display: inline-block;
    cursor: pointer;
  }
  &-rate {
    display: flex;
    align-items: center;
    font-size: 12px;
  }
}

.shop-goodsList {
  margin: 30px 5px;
  // border:1px solid #eee;
  &-item {
    margin-bottom: 20px;
    text-align: left;
  }
  &-name {
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* 这里是超出几行省略 */
  }
}
::v-deep .ivu-avatar-large {
  width: 70px;
  height: 70px;
}
::v-deep .ivu-rate-star {
  margin-right: 0;
}
</style>
