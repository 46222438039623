<template>
  <div class="coupon-item">
    <div class="coupon-wrap" v-for="(item,index) in coupon" :key="index">
      <div class="header">
        <p class="flex">
          <ticket theme="outline" size="20" fill="#fff"/>
          {{item.shop_name}}
        </p>
        <!-- <p>Get Coupons</p> -->
      </div>
      <div class="content">
        <p class="content-left">
          <span class="symbol">{{ item.currency_symbol }}</span>
          <span class="discountMoney"> {{item.dec_price}}</span>
        </p>
        <div class="content-center">
          <p class=""> {{ $t('每满') }} {{ item.man_price }} {{ $t('减')}}</p>
          <span>COUPONS</span>
        </div>
        <div class="content-right">
          <p class="desc1">{{item.end_time}}</p>
          <p class="desc2">{{ $t('有效期') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {Ticket} from "@icon-park/vue"
export default {
  name:'Theme1',
  components:{
    Ticket,
  },
  props:{
    coupon:{
      type:Array,
      required:true,
      default: () => {
        return []
      }
    }
  },
}
</script>

<style lang="scss" scoped>
  @import "@/common/style/common.scss";
  .coupon-item{
    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .coupon-wrap{
    width:300px;
    background-color: #eee;
    border-radius: 14px;
    overflow: hidden;
    margin: 5px;
  }
.header{
  padding:5px 10px;
  background-color: #ffb648;
  color:#fff;
  @include flex($jc:space-between)
}
.content{
  @include flex();
  padding:15px 10px;
  &-left{
    padding: 0 10px;
  }
  &-center{
    padding:0 10px 0 20px;
    margin-right: 10px;
    border-right: 1px solid #999;
    font-weight: bolder;
  }
  &-right{
    text-align: center;
    margin:0 auto;
  }
}
.symbol{
  font-size: 14px;
}
.discountMoney{
  font-size: 30px;
  font-weight: bold;
}
.desc1{
  background-color: #ffb648;
  color:#333;
  display: inline-block;
  border-radius: 40px;
  padding:1px 6px;
}
.desc2{
  font-size: 12px;
  color:#666
}
</style>
