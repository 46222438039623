<template>
  <div class="coupon-wrap" id="Brand">
    <Title  arrowType="'round'" url="/ShopStreet">
      <template #title>
        <h1 class="title">
          <span class="title-end">{{$t('店铺街')}}</span>
        </h1>
      </template>
    </Title>
    <div>
      <ul class="coupon-items">

        <li
          class="coupon-items-li"
          v-for="(item, i) in recommendShops"
          :key="`coupon-${i}`"
          @click="goMerchant(item)"
        >

            <div class="avatar-wrap">
              <Avatar
                size="small"
                :src="item.logo"
              />
              <span class="shop-name">{{ item.shop_name }}</span>
            </div>
            <!-- <Tag color="volcano">自营</Tag> -->
            <p class="focus">{{item.coll_num}} {{$t('人已抢')}}</p>
          <img :src="item.thumb_url || item.logo" class="coupon-items-li-coverImg" />
        </li>

      </ul>
    </div>
  </div>
</template>
<script>
import Title from "../Title";
export default {
  name: "Brand",
  props:{
    recommendShops:{
      type:Array,
      required:true,
      default:()=>[]
    }
  },
  data() {
    return {
    };
  },
  components: {
    Title,
  },
  methods:{
    goMerchant(item){
      this.$router.push({path:'/merchant',query:{shop_id:item.id}})
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/common/style/common.scss";
.coupon {
  &-wrap {
    @extend .common-wrap;
    padding: 10px 20px 0;
    height: 350px;
  }
  &-items {
    overflow-y: auto;
    height: 275px;
    &::-webkit-scrollbar {
      background-color: #eee;
      width: 4px;
    }
    &-li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      background-color: #eee;
      margin-bottom: 8px;
      cursor: pointer;
      &-bottom {
        padding: 10px 5px 20px;
        &-title {
          margin-bottom: 6px;
        }
      }
      &-coverImg {
        width: 80px;
        height: 80px;
      }
    }
  }
}

.avatar-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}
.shop-name {
  margin-left: 4px;
}
.focus {
  font-size: 12px;
  color: #999;
  margin-top: 4px;
}
.title {
  font-size: 0;
  & > span {
    font-size: 24px;
    font-weight: 800;
  }
  &-front {
    color: #333;
  }
  &-end {
    color: #e2231a;
  }
}
</style>
