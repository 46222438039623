<template>
  <!-- 登录页面 -->
    <div class="container-wrap">
          <div class="login-container">
            <div class="form-box" style="width: 432px">
              <div class="form-tab">
                <span :class="['form-tab-name',loginType===item.type?'form-tab-name-active':'']"  v-for="(item,i) in tabList"  :key="`item-${i}`" @click="tabChange(item.type)">{{item.name}}</span>
              </div>
              <div>
                <div prop="phone" class="form-item" v-if="loginType === '0'">
                    <selectCountryList/>
                    <input class="form-input" type="text" v-model="formDate.phone"  :placeholder="$t('请输入手机号')" />
                </div>
                <div prop="email" class="form-item" v-if="loginType === '1'">
                    <input class="form-input" v-model="formDate.email"  :placeholder="$t('请输入邮箱')" />
                </div>
                <div prop="password" class="form-item">
                    <input class="form-input" type="password" v-model="formDate.password" :placeholder="$t('请输入密码')" />
                </div>
                <div class="policy">

                  <div class="policy-left" @click="onPolicyCheck">
                      <Icon  v-if="agreeCheck" size="21" type="md-checkmark-circle"  color="#e2231a"/>
                     <span class="policy-dot" v-if="!agreeCheck" />
                   {{$t('我已阅读并同意')}}
                  </div>
                  <router-link target="_blank" :to="{path:'/ArticleDetail',query:{title:$t('用户隐私政策')}}">
                 <span class="policy-a"> {{$t('《用户隐私政策》')}} </span>
                </router-link>
                </div>
                    <Button class="main-btn"  type="error" size="large" @click="handleSubmit('formInline')" long>{{$t('登录')}}</Button>
              </div>
            <p class="bottom"> {{$t('没有账号')}}，
              <router-link :to="{path:'Register'}">
                <span class="bottom-link">{{$t('立即注册')}}？</span>
              </router-link>
            </p>
            </div>
          </div>
    </div>
  </template>

  <script>
  import store from '@/vuex/store';
  import { mapMutations, mapActions } from 'vuex';
  import mixin from "./mixins"
  import * as phonenumbers from 'libphonenumber-js';
	
  export default {
    mixins:[mixin],
    name: 'LoginBlock',
    data () {
      return {
        tabList: [
         {
          type: '0',
          name: this.$t('手机登录')
         },
         {
          type: '1',
          name: this.$t('邮箱登录')
         }
        ],
        loginType: '0'
      };
    },
    components:{
      selectCountryList :()=>import('./countryList')
    },
    created(){
      const { phone } = this.$route.query || ''
    },
    methods: {
      ...mapActions(['login']),
      onPolicyCheck(){
        this.agreeCheck = !this.agreeCheck
      },
      tabChange(type){
        this.loginType = type
      },
      handleSubmit (name) {
          const {formDate:{phone,password,email},agreeCheck, loginType} = this
          if(!phone && loginType === '0'){
           return this.$Message.error(this.$t('请输入手机号'))
          }
          const phoneNumberStr = '+' + this.$store.state.nationCodeData.country_code + phone
						
          const phoneNumber = phonenumbers.parse(phoneNumberStr);
          const isValid = phonenumbers.isValidNumber(phoneNumber);
						
          if(!isValid && loginType === '0'){
              return this.$Message.error(this.$t('请输入正确的手机号'))
          }
          if(!email && loginType === '1'){
            return this.$Message.error(this.$t('请输入邮箱'))
          }
          if(!password){
            return this.$Message.error(this.$t('请输入密码'))
          }
          if(password.length<6){
            return this.$Message.error(this.$t('请输入不小于6位数的密码'))
          }
          if(!agreeCheck){
          return this.$Message.error(this.$t('请勾选协议'))
        }
        this.login({phone,password, email, type: loginType}).then(() => {
          this.$store.dispatch('getUserInfo').then(res => {
            if (!res.phone) {
              this.$Modal.confirm({
                title: this.$t('温馨提示'),
                okText: this.$t('去绑定'),
                cancelText: this.$t('取消'),
                content: this.$t('您还未绑定手机号，是否去绑定手机号？'),
                onOk: () => {
                  this.$router.push('/my?name=MyAccount&isBindPhone=1')
                },
                onCancel: () => {
                }
              })
            }
          })
        })
      }
    }
  };
  </script>

  <style lang="scss" scoped>

  .container{
    &-wrap{
    width: 100%;
    margin: 0 auto;
    height: 545px;
    position: relative;
    overflow: hidden;
    }
    &-bg{
      position: absolute;
      left: 0;
      top:0;
      width: 100%;
    }
  }

  .login-container {
    position: absolute;
    right: 200px;
  }

  .form{
    &-box{
    padding:40px 50px;
    margin: 30px auto;
    background-color: #Fff;
    }
    &-tab{
      display: flex;
      cursor: pointer;
      justify-content: space-around;
      margin-bottom: 30px;
      &-name{
        font-size: 20px;
        font-weight: 500;
        transition: all 0.4s;
        &-active{
          color: #e2231a;
        }
      }
    }
  }
  .main-btn{
   height: 50px;
   background-color: #e2231a;
   border-radius: 0;
  }

  .form{
    &-item{
      display: flex;
      align-items: center;
      border:1px solid #dcdcdc;
      margin:20px 0;
    }
    &-input{
      width:240px;
      border: none;
      padding:0 15px;
      height: 48px;
      outline: 0;
      color:#666;

    }
    &-label{
      padding:0 24px;
      height: 48px;
      line-height: 48px;
      border-right:1px solid #dcdcdc
    }
  }
  .bottom{
    text-align: center;
    color:#bcbcbc;
    margin-top: 20px;
    &-link{
      color:#e2231a;
    }
  }

  .policy{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
    &-a{
      color:#e2231a;
    }
    &-left{
      display: flex;
      align-items: center;

    }
    &-dot{
      width:16px;
      height: 16px;
      border-radius: 80px;
      border: 1px solid #dcdcdc;
      flex-shrink: 0;
      display: inline-block;
      margin-right: 5px;
    }

  }


  ::v-deep .ivu-input-wrapper{
    width:100%;
  }

  </style>
