import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import ViewUI from "view-design";
import VueI18n from 'vue-i18n'
import "view-design/dist/styles/iview.css";
import { install } from '@icon-park/vue/es/all';
import "@icon-park/vue/styles/index.css";
// import 'windi.css'
import { Empty,Search } from "@/components"
import store from "./vuex/store"
import messages from './locale/index'

import router from "./router";
import api from "./api";
import * as utils from "./common/utils"
const dayjs = require("dayjs");

import LemonIMUI from "lemon-imui"
import 'lemon-imui/dist/index.css';
import lemonMessageGoods from '@/components/im/lemonMessageGoods'
import hevueImgPreview from 'hevue-img-preview'

Vue.component('lemonMessageGoods', lemonMessageGoods)

Vue.use(hevueImgPreview, {
  keyboard: true,
  clickMaskCLose: true
})

Vue.use(LemonIMUI)
Vue.use(ViewUI)
Vue.use(install)

Vue.prototype.$http = api;
Vue.prototype.$utils = utils;
Vue.prototype.$store = store;
Vue.prototype.$dayjs = dayjs

Vue.component('Empty',Empty)
Vue.component('Search',Search)

Vue.use(VueI18n)

router.beforeEach((to, from, next) => {
  ViewUI.LoadingBar.start();
  next();
});

router.afterEach(route => {
  ViewUI.LoadingBar.finish();
});

let i18nConfig = {
	locale: store.getters.getLocalLang.code,
	messages
}
const i18n = new VueI18n(i18nConfig)
//多语言


const _vue = new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
Vue.filter('T',v=>{
  if(!v) return ''
  return _vue.$t(v)
})