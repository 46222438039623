<template>
  <div class="nav-body w">
    <div class="nav-header">
      <div class="nav-header-first">{{'全部分类' | T}}</div>
      <ul class="nav-header-items">
        <li
          class="nav-header-items-li"
          v-for="(item, index) in navData"
          :key="`navItem-${index}`"
          @click="goNav(item)"
        >
          <a class="nav-header-items-a" href="#" :title="item.menu_name">{{ item.menu_name }}</a>
        </li>
      </ul>
    </div>

    <div class="nav-content">
      <!-- 幻灯片 -->
      <Carousel autoplay loop :height="420">
        <CarouselItem
          v-for="(item, index) in bannerList"
          :key="`CarouselItem-${index}`"
        >
          <router-link :to="{path:`${ item.ad_url_type}`}">
            <img :src="item.ad_pic" />
          </router-link>
        </CarouselItem>
      </Carousel>
    </div>
    <!-- 侧边导航 -->
    <div class="nav-side" ref="navSide">
      <ul>
        <li v-for="(item,i) in getLevel1List" :key="item.id" @mouseenter="showDetail(i)" @mouseleave="hideDetail(i)">
          <span class="nav-side-item">{{item}}</span>
          <Icon type="ios-arrow-forward" color="#b6b6b6" />
        </li>
      </ul>
    </div>
    <div class="nav-side-subpanel">
      <transition name="fade">
      <div
        class="detail-item-panel panel-2"
        :duration="{ enter: 100, leave: 100 }"
        v-show="subPanel"
        @mouseenter="showDetail(sideIndex)"
        ref="itemPanel1"
        @mouseleave="hideDetail(sideIndex)"
      >
        <ul>
          <li
            v-for="(items, index) in getLevel2List"
            :key="index"
            class="detail-item-row"
          >
            <span class="detail-item-title"
              >{{ items.cate_name }}
              <span class="glyphicon glyphicon-menu-right"></span>
            </span>
            <router-link
              :to="{path:'/goodsList',query:{cate_id:item.id}}"
              v-for="(item, subIndex) in items.three"
              :key="subIndex"
            >
              <span class="detail-item">{{ item.cate_name }}</span>
            </router-link>
          </li>
        </ul>
      </div>
    </transition>
    </div>

  </div>
</template>

<script>
import store from "@/vuex/store";
import { mapState } from "vuex";
export default {
  name: "HomeNav",
  data() {
    return {
      subPanel:false,
      sideIndex:0,
      navList:[],
      navData:[]
    };
  },
  props:{
    bannerList:{
      type:Array,
      default:()=>[],
      required:true
    }
  },
  computed: {
    ...mapState(["marketing"]),
    getLevel1List(){
      return this.navList.map(res=>res.cate_name) || []
    },
    getLevel2List(){
      const {navList,sideIndex} = this
      return navList[sideIndex] && navList[sideIndex]['three']|| []
    },
  },
  methods: {
    showDetail(index) {
      this.subPanel = true
      this.sideIndex = index
    },
    hideDetail() {
      this.subPanel = false
    },
    goNav(item){
      this.$router.push({path:item.menu_url})
    }
  },
  store,
};
</script>

<style lang="scss" scoped>
.nav-header {
  display: flex;
  background-color: #fff;
  &-first {
    background-color: #e2231a;
    color: #fff;
    padding: 10px 0;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    width: 200px;
    margin-right: 20px;
  }
  &-items {
    display: flex;
    &-a {
      max-width: 140px;
      padding: 7px 22px;
      color: #333;
      display: block;
      font-size: 16px;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.nav-item {
  width: 680px;
  height: 36px;
  margin: 0px auto;
}
.nav-item ul {
  list-style: none;
  margin-left: 15px;
}
.nav-item li {
  float: left;
  font-size: 16px;
  font-weight: bold;

  margin-left: 30px;
}
.nav-item a {
  text-decoration: none;
  color: #555555;
}
.nav-item a:hover {
  color: #d9534f;
}
/*大的导航信息，包含导航，幻灯片等*/
.nav-body {
  height: 461px;
  margin: 0 auto;
  position: relative;
}
.nav-side {
  height: 420px;
  color: #333;
  width: 200px;
  background-color: rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: 100;
  padding-top: 15px;
  overflow: auto;
}
.nav-side ul {
  width: 100%;
  padding: 0px;
  list-style: none;
}
.nav-side li {
  padding: 9px 15px;
  font-size: 14px;
  line-height: 18px;
  position: relative;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.nav-side li:hover {
  background: #e2231a;
}
.nav-side-item {
  color: #fff;
  font-weight: 800;
}
.nav-side-item:hover {
  cursor: pointer;
}

/*导航内容*/
.nav-content {
  position: absolute;
  overflow: hidden;
  z-index: 10;
  top: 40px;
  left: 0;
  width: 100%;
}
/*导航图片*/
.nav-show-img {
  margin-top: 10px;
  float: left;
}
.nav-show-img:nth-child(2) {
  margin-left: 12px;
}
/*显示商品*/
.content {
  width: 100%;
}
/*显示商品详细信息*/

.nav-side-subpanel{
  background-color: #fff;
  position: absolute;
  left:200px;
  top:40px;
  z-index: 999;
  box-shadow: 0 -10px 10px #eee;
  padding:10px 0;
}
.detail-item-panel {
  width: 905px;
  height: 420px;
  overflow: hidden;

}
.nav-detail-item {
  margin-left: 26px;
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
  color: #eee;
}
.nav-detail-item span {
  padding: 6px;
  padding-left: 12px;
  margin-left: 15px;
  font-size: 12px;
  background-color: #333;
}
.nav-detail-item span:hover {
  margin-left: 15px;
  background-color: #f44336;
}
.detail-item-panel ul {
  list-style: none;
}
.detail-item-panel li {
  line-height: 38px;
  margin-left: 40px;
}
.detail-item-title {
  padding-right: 6px;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  color: #333;
  width: 90px;
  display: inline-block;
}
.detail-item-title:hover {
  color: #d9534f;
}
.detail-item-row a {
  color: #555555;
}
.detail-item {
  font-size: 14px;
  padding-left: 12px;
  padding-right: 8px;
  color:#999;
  cursor: pointer;
}
.detail-item:hover {
  color: #d9534f;
}
::v-deep .ivu-carousel-item img {
  width: 100%;
}
</style>
