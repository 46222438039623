<template>
  <div class="title-wrap">
    <div class="title-wrap-left">
      <slot name="title">
        <h1 class="title-wrap-left-title">{{ title }}</h1>
      </slot>
      <span class="title-wrap-left-subTitle" v-if="subTitle.length">{{subTitle}}</span>
    </div>
    <router-link :to="url">
      <div class="title-wrap-right" v-if="arrowType === 'square'">
        <span>{{'查看更多' | T}}</span>
        <Icon type="ios-arrow-forward" />
      </div>
      <Icon v-else type="ios-arrow-dropright" :size="arrowSize" color="#e2231a" />
    </router-link>
  </div>
</template>

<script>
  export default {
    name: "Title",
    props: {
      title: {
        type: String,
        default: "",
      },
      titleType: {
        type: String,
        default: "Text",
      },
      subTitle: {
        type: String,
        default: "",
      },
      arrowType: {
        type: String,
        default: "square",
      },
      arrowSize: {
        type: Number,
        default: 28,
      },
      url: {
        type: String,
        default: "/",
      },
    },
  };
</script>

<style lang="scss" scoped>
  .title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    &-left {
      display: flex;
      align-items: center;

      &-title {
        font-size: 24px;
        font-weight: bold;
      }

      &-subTitle {
        font-size: 14px;
        color: #666;
        margin-left: 10px;
      }
    }

    &-right {
      border: 1px solid #c8caca;
      padding: 2px 8px;
      color: #999;
      font-size: 12px;
      cursor: pointer;
    }
  }
</style>
