<template>
  <div>
    <h2 class="title">{{$t('收货地址')}}</h2>
    <div class="address-wrap">
      <AddressItem
        v-for="(item, i) in addressList"
        :key="`address-${i}`"
        @addressItemClick="selectAddress(data,i)"
        :addressItem="item"
        :active="selectIndex === i"
      ></AddressItem>
      <div class="add-box" @click="addAddress">
        <Icon type="ios-add" />
        <p>{{$t('添加收货地址')}}</p>
      </div>
    </div>

    <AddAddress ref="addressRef" @onConfirm="onAddConfirm"></AddAddress>
  </div>
</template>
<script>
import AddressItem from "./AddressItem";
import AddAddress from "./AddAddress";
import {mapActions, mapState} from "vuex";
export default {
  name: "AddressBar",
  data() {
    return {
      selectIndex: 0,
    };
  },
  created(){
    this.actionAddressList({type:'get'})
  },
  computed: {
    ...mapState(["addressList"])
  },
  methods: {
    ...mapActions(['actionAddressList']),
    selectAddress(data,i) {
      this.$store.commit('SET_ADDRESS_INDEX',i)
      this.selectIndex = i;
    },
    addAddress() {
      this.$refs.addressRef.handleRender();
    },
  async onAddConfirm(data){
      console.log({data})
      const {name:contacts,phone,address,detailAddress,isDefault} = data
      const loaction = {
        province:address,
        pro_id:0,
        city:address,
        city_id:0,
        area:address,
        area_id:0,
        datavalue: ""
      }
      const {status}  = await this.actionAddressList({...loaction,address:detailAddress,phone,contacts,is_default:isDefault,type:'add'})
      if(status===200){
        this.$refs.addressRef.onCancel();
      }
    }
  },
  components: {
    AddressItem,
    AddAddress,
  },
};
</script>
<style lang="scss" scoped>
@import "@/common/style/common.scss";

.borderBottom {
  border-bottom: 1px solid #eee;
}
.commonWrap {
  margin: 0 30px;
  padding: 16px 0;
  @extend .borderBottom;
}
.price {
  font-size: 16px;
  font-weight: bold;
  color: #e2231a;
}
.title {
  font-size: 20px;
  padding: 15px 20px;
  font-weight: 600;
  position: relative;
  background-color: #fff;
  &::before {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 2px;
    background: repeating-linear-gradient(
      -45deg,
      #ff6c6c,
      #ff6c6c 20%,
      transparent 0,
      transparent 25%,
      #1989fa 0,
      #1989fa 45%,
      transparent 0,
      transparent 50%
    );
    background-size: 80px;
    content: "";
  }
}

.address-wrap {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 30px;
  background-color: #fff;
}
.add-box {
  width: 260px;
  display: flex;
  height: 125px;
  align-items: center;
  justify-content: center;
  border: 1px solid #eee;
  cursor:pointer;
}




</style>
