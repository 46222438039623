<template>
  <div>
    <component :is="type"></component>
  </div>
</template>

<script>
import Login from './Login'
import Register from './Register'
export default {
  name:'Authen',
  props:{
    type:{
      type:String,
      default:'Login'
    }
  },
    components:{
      Login,
      Register
    }
}
</script>


