<template>
  <div>
    <footer class="footer">
      <div class="icon-row w">
        <div class="footer-icon">
          <Icon type="md-cart" size="22" color="#696c7f" />
          <span class="footer-icon-text">{{$t('品类齐全，轻松购物')}}</span>
        </div>
        <div class="footer-icon">
          <Icon type="ios-boat" size="22" color="#696c7f" />
          <span class="footer-icon-text">{{ $t('多仓直发，极速配送')}}</span>
        </div>
        <div class="footer-icon">
          <Icon type="md-trophy" size="22" color="#696c7f" />
          <span class="footer-icon-text">{{ $t('正品行货，精致服务')}}</span>
        </div>
        <div class="footer-icon">
          <Icon type="md-ribbon" size="22" color="#696c7f" />
          <span class="footer-icon-text">{{$t('天天低价，畅选无忧')}}</span>
        </div>
      </div>
      <div class="service-intro">
<!--        <div class="servece-type w">-->
<!--          <div-->
<!--            class="servece-type-info"-->
<!--            v-for="(guide, index) in guideArr"-->
<!--            :key="index"-->
<!--          >-->
<!--            <ul>-->
<!--              <li v-for="(item, index) in guide" :key="index">{{ item }}</li>-->
<!--            </ul>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="clearfix"></div>-->
        <div class="friend-link">
          <div class="friend-link-item">
            <ul>
              <!-- <li v-for="(link, index) in moreLink" :key="index">
                <span
                  :class="[linkItemClass, { 'link-last-item': index === 9 }]"
                  >{{ link }}</span
                >
              </li> -->
            </ul>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="copyright">
          <p>{{webConfig.web_banquan || ""}}</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      linkItemClass: "link-item",
      guideArr: [
        [
          "购物指南",
          "购物流程",
          "会员介绍",
          "生活旅行",
          "常见问题",
          "大家电",
          "联系客服",
        ],
        [
          "配送方式",
          "上门自提",
          "211限时达",
          "配送服务查询",
          "收取标准",
          "海外配送",
        ],
        [
          "支付方式",
          "货到付款",
          "在线支付",
          "分期付款",
          "邮局汇款",
          "公司转账",
        ],
        [
          "售后服务",
          "售后政策",
          "价格保护",
          "退款说明",
          "返修/退换货",
          "取消订单",
        ],
      ],
      moreLink: [
        this.$t("关于我们"),
        this.$t("联系我们"),
        this.$t("联系客服"),
        this.$t("合作招商"),
        this.$t("帮助中心"),
        this.$t("隐私政策"),
      ],
      webConfig:{
        web_banquan:'',
      },
    };
  },
  created(){
    setTimeout(() => {
      let webConfigStr = localStorage.getItem("webConfig");
      this.webConfig = JSON.parse(webConfigStr)
    }, 3000);
  },
};
</script>

<style  scoped>
/*****************************底 部 开 始*****************************/
.footer {
  width: 100%;
  height: 220px;
  background-color: #ececec;
  overflow: hidden;
}
.icon-row {
  margin: 15px auto;
  padding-top: 8px;
  height: 64px;
  display: flex;
  justify-content: space-between;
}
.footer-icon {
  display: flex;
  align-items: center;
}
.footer-icon-text {
  font-size: 16px;
  font-weight: bold;
}
.service-intro {
  width: 100%;
  border-top: 1px solid #d9d9d9;
}
.servece-type {
  margin: 15px auto;
  height: 200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.servece-type-info {
  color: #666666;
}
.servece-type-info ul {
  list-style: none;
}
.servece-type-info li {
  font-size: 14px;
  cursor: pointer;
  line-height: 26px;
}
.servece-type-info li:first-child {
  font-size: 16px;
  line-height: 28px;
  font-weight: bold;
}
.friend-link {
  display: flex;
  align-items: center;
  width: 908px;
  height: 30px;
  margin: 20px auto;
  color: #666;
  /*border-top: 1px solid #d9d9d9;*/
}
.friend-link-item {
  margin: 0px auto;
}
.friend-link-item ul {
  list-style: none;
}
.friend-link-item li {
  padding: 5px 0px;
  float: left;
}
.link-item {
  padding: 0px 8px;
  cursor: pointer;
  border-right: 1px solid #e7e3e7;
}
.link-last-item {
  border: none;
}
.copyright {
  width: 100%;
  color: #666;
  line-height: 30px;
  text-align: center;
}
.copyright a {
  color: #232323;
  font-size: 20px;
}
::v-deep .ivu-icon {
  width: 40px;
  height: 40px;
  border-radius: 200px;
  border: 1px solid #696c7f;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
}
/*****************************底 部 结 束*****************************/
</style>
