<template>
  <Row class="goods-wrap" id="GuessLike" :gutter="10">
    <Col
      span="5.8"
      class="goods-item"
      v-for="(item, i) in goodsList"
      :key="`$goods-${i}`"
    >
      <GoodsItem :item="item" />
    </Col>
  </Row>
</template>

<script>
import GoodsItem from "./GoodsItem";
export default {
  props: {
    goodsList: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  components: {
    GoodsItem,
  },
};
</script>

<style lang="scss" scoped>
.goods {
  &-wrap {
    display: flex;
    flex-wrap: wrap;
  }
  &-item {
    width: 20%;
    &:nth-child(n + 6) {
      margin-top: 13px;
    }
  }
}
</style>
