<template>
    <Modal
        v-model="authenModal"
        footer-hide="true"
        @on-ok="ok"
        @on-cancel="cancel"

        >
        <Authen />
    </Modal>
</template>

<script>
import Authen from './index'
export default {
  name:'AuthenModal',
  data(){
    return {
      authenModal:true
    }
  },
  components:{
    Authen
  },
  methods:{
    ok(){

    },
    cancel(){

    }
  }
}
</script>

<style>

</style>
