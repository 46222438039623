import { mapState,mapMutations } from "vuex"

export default {
  data(){
    return {
      agreeCheck:false,
      formDate:{
        phone:'',
        phonecode:'',
        password:''
      }
    }
  },
  computed:{
    ...mapState(['nationCodeData'])
  },
  methods:{
    onPolicyCheck(){
      this.agreeCheck = !this.agreeCheck
    },
    sendSmsCode(){
      const {type, formDate:{phone}, nationCodeData:{country_code} } = this
      this.$http.getSmsCode({type,phone,country_code}).then(res=>{
        const {status,mess} = res
        if(status===200){
          this.$Message.success({
            content:mess,
            duration: 4,
            closable: true
         });
        }
      })
    },
    valid(params){

    }
  }
}
