import apiList from "@/api"
import ViewUI from "view-design";
import router from "@/router"
// 获取秒杀数据
export const loadSeckillsInfo = ({ commit }) => {
  return new Promise((resolve, reject) => {
    const data = [
      {
        intro: "乔丹质燥大闹天宫联名款运动T恤男女2020夏季新款针织国潮短袖T恤",
        img: "static/img/index/seckill/seckill-item1.jpg",
        price: 171.9,
        realPrice: 189.6
      },
      {
        intro: "花花公子长袖t恤男士秋冬季卫衣2020新款潮流秋衣打底衫加绒上衣",
        img: "static/img/index/seckill/seckill-item3.jpg",
        price: 21.8,
        realPrice: 49.0
      },
      {
        intro: "安奈儿童装女童高翻领长袖T恤2020冬新款保暖洋气时尚男童打底衫",
        img: "static/img/index/seckill/seckill-item4.jpg",
        price: 19.9,
        realPrice: 39.0
      },
      {
        intro: "优衣库 【设计师合作款】男装/女装 圆领T恤(长袖) 429158 UNIQLO",
        img: "static/img/index/seckill/seckill-item5.jpg",
        price: 29.9,
        realPrice: 39.9
      },
      {
        intro: "优衣库 男装/女装 AIRism圆领T恤(长袖) 433839 UNIQLO",
        img: "static/img/index/seckill/seckill-item6.jpg",
        price: 59.9,
        realPrice: 159.9
      }
    ];
    const date = new Date();
    const hours = date.getHours();
    const minute = date.getMinutes();
    const seconds = date.getSeconds();
    console.log([hours, minute, seconds]);
    // 距离开始秒杀时间
    const deadline = {
      hours: 1,
      minute: 38,
      seconds: 36
    };
    commit("SET_SECKILLS_INFO", [data, deadline]);
  });
};

// 获取轮播(营销)图片
export const loadCarouselItems = ({ commit }) => {
  return new Promise((resolve, reject) => {
    const data = {
      carouselItems: [
        "static/img/nav/1.jpg",
        "static/img/nav/2.jpg",
        "static/img/nav/3.jpg",
        "static/img/nav/4.jpg",
        "static/img/nav/5.jpg"
      ],
      activity: [
        "static/img/nav/nav_showimg1.jpg",
        "static/img/nav/nav_showimg2.jpg"
      ]
    };
    commit("SET_CAROUSELITEMS_INFO", data);
  });
};

export const loadComputer = ({ commit }) => {
  return new Promise((resolve, reject) => {
    const computer = {
      title: "口红专柜",
      link: [
        "美容仪",
        "美甲",
        "手霜手膜",
        "香水",
        "女神频道",
        "美容美体",
        "睫毛膏",
        "修容",
        "化妆工具"
      ],
      detail: [
        {
          bigImg: "static/img/index/computer/item-computer-1.jpg",
          itemFour: [
            {
              title: "美容仪",
              intro: "999元限量秒！",
              img: "static/img/index/computer/item-computer-2.jpg"
            },
            {
              title: "美甲",
              intro: "1000减618",
              img: "static/img/index/computer/item-computer-1-3.jpg"
            },
            {
              title: "手霜手膜",
              intro: "联合满减最高省618",
              img: "static/img/index/computer/item-computer-1-4.jpg"
            },
            {
              title: "香水",
              intro: "5折神券 精品",
              img: "static/img/index/computer/item-computer-1-5.jpg"
            }
          ],
          itemContent: [
            "static/img/index/computer/item-computer-1-6.jpg",
            "static/img/index/computer/item-computer-1-7.jpg",
            "static/img/index/computer/item-computer-1-8.jpg"
          ]
        },
        {
          bigImg: "static/img/index/computer/item-computer-2-1.jpg",
          itemFour: [
            {
              title: "女神频道",
              intro: "爆款12期免息",
              img: "static/img/index/computer/item-computer-2-2.jpg"
            },
            {
              title: "美容美体",
              intro: "抢999减666神券",
              img: "static/img/index/computer/item-computer-2-3.jpg"
            },
            {
              title: "睫毛膏",
              intro: "低至5折",
              img: "static/img/index/computer/item-computer-2-4.jpg"
            },
            {
              title: "修容",
              intro: "大牌5折抢",
              img: "static/img/index/computer/item-computer-2-5.jpg"
            }
          ],
          itemContent: [
            "static/img/index/computer/item-computer-2-6.jpg",
            "static/img/index/computer/item-computer-2-7.jpg",
            "static/img/index/computer/item-computer-2-8.jpg"
          ]
        }
      ]
    };
    commit("SET_COMPUTER_INFO", computer);
  });
};

export const loadEat = ({ commit }) => {
  return new Promise((resolve, reject) => {
    const eat = {
      title: "女神休闲骚包",
      link: [
        "女士斜挎包",
        "女士帆布包",
        "女士手提包",
        "女士手拿包",
        "女士真皮包",
        "女士单肩包"
      ],
      detail: [
        {
          bigImg: "static/img/index/eat/item-eat-1-1.jpg",
          itemFour: [
            {
              title: "女士斜挎包",
              intro: "买2免1",
              img: "static/img/index/eat/item-eat-1-2.jpg"
            },
            {
              title: "女士帆布包",
              intro: "第二件半价",
              img: "static/img/index/eat/item-eat-1-3.jpg"
            },
            {
              title: "女士手提包",
              intro: "满99减40",
              img: "static/img/index/eat/item-eat-1-4.jpg"
            },
            {
              title: "女士手拿包",
              intro: "满199减100",
              img: "static/img/index/eat/item-eat-1-5.jpg"
            }
          ],
          itemContent: [
            "static/img/index/eat/item-eat-1-6.jpg",
            "static/img/index/eat/item-eat-1-7.jpg",
            "static/img/index/eat/item-eat-1-8.jpg"
          ]
        },

        {
          bigImg: "static/img/index/eat/item-eat-2-1.jpg",
          itemFour: [
            {
              title: "女包",
              intro: "秋上新",
              img: "static/img/index/eat/item-eat-2-2.jpg"
            },
            {
              title: "男包",
              intro: "10格十格单肩包",
              img: "static/img/index/eat/item-eat-2-3.jpg"
            },
            {
              title: "双肩包",
              intro: "女包手提铂金包",
              img: "static/img/index/eat/item-eat-2-4.jpg"
            },
            {
              title: "拉杆箱",
              intro: "牛皮真皮畅销款女包",
              img: "static/img/index/eat/item-eat-2-5.jpg"
            }
          ],
          itemContent: [
            "static/img/index/eat/item-eat-2-6.jpg",
            "static/img/index/eat/item-eat-2-7.jpg",
            "static/img/index/eat/item-eat-2-8.jpg"
          ]
        }
      ]
    };
    commit("SET_EAT_INFO", eat);
  });
};

// 请求获得商品详细信息
export const loadGoodsInfo = ({ commit }) => {
  commit("SET_LOAD_STATUS", true);
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const data = {
        goodsImg: [
          "static/img/goodsDetail/item-detail-1.jpg",
          "static/img/goodsDetail/item-detail-2.jpg",
          "static/img/goodsDetail/item-detail-3.jpg",
          "static/img/goodsDetail/item-detail-4.jpg"
        ],
        title: "苹果8/7手机壳iPhone7 Plus保护壳全包防摔磨砂硬外壳",
        tags: ["满69-20元", "关注产品★送钢化膜", "配次日达"],
        discount: ["满148减10", "满218减20", "满288减30"],
        promotion: ["跨店满减", "多买优惠"],
        remarksNum: 6000,
        setMeal: [
          [
            {
              img: "static/img/goodsDetail/pack/1.jpg",
              intro: "4.7英寸-深邃蓝",
              price: 28.0
            },
            {
              img: "static/img/goodsDetail/pack/2.jpg",
              intro: "4.7英寸-星空黑",
              price: 29.0
            },
            {
              img: "static/img/goodsDetail/pack/3.jpg",
              intro: "5.5英寸-香槟金",
              price: 28.5
            }
          ],
          [
            {
              img: "static/img/goodsDetail/pack/4.jpg",
              intro: "5.5英寸-玫瑰金",
              price: 32.0
            },
            {
              img: "static/img/goodsDetail/pack/5.jpg",
              intro: "5.5英寸-深邃蓝",
              price: 32.0
            },
            {
              img: "static/img/goodsDetail/pack/6.jpg",
              intro: "5.5英寸-星空黑",
              price: 35.0
            }
          ],
          [
            {
              img: "static/img/goodsDetail/pack/7.jpg",
              intro: "4.7英寸-香槟金",
              price: 26.0
            },
            {
              img: "static/img/goodsDetail/pack/8.jpg",
              intro: "4.7英寸-玫瑰金",
              price: 25.0
            },
            {
              img: "static/img/goodsDetail/pack/9.jpg",
              intro: "4.7英寸-中国红",
              price: 28.0
            }
          ]
        ],
        hot: [
          {
            img: "static/img/goodsDetail/hot/1.jpg",
            price: 28.0,
            sale: 165076
          },
          {
            img: "static/img/goodsDetail/hot/2.jpg",
            price: 36.0,
            sale: 135078
          },
          {
            img: "static/img/goodsDetail/hot/3.jpg",
            price: 38.0,
            sale: 105073
          },
          {
            img: "static/img/goodsDetail/hot/4.jpg",
            price: 39.0,
            sale: 95079
          },
          {
            img: "static/img/goodsDetail/hot/5.jpg",
            price: 25.0,
            sale: 5077
          },
          {
            img: "static/img/goodsDetail/hot/6.jpg",
            price: 20.0,
            sale: 3077
          }
        ],
        goodsDetail: [
          "static/img/goodsDetail/intro/1.jpg",
          "static/img/goodsDetail/intro/2.jpg",
          "static/img/goodsDetail/intro/3.jpg",
          "static/img/goodsDetail/intro/4.jpg"
        ],
        param: [
          {
            title: "商品名称",
            content: "iPhone 7手机壳"
          },
          {
            title: "商品编号",
            content: "10435663237"
          },
          {
            title: "店铺",
            content: "Gavin Shop"
          },
          {
            title: "商品毛重",
            content: "100.00g"
          },
          {
            title: "商品产地",
            content: "中国大陆"
          },
          {
            title: "机型",
            content: "iPhone 7"
          },
          {
            title: "材质",
            content: "PC/塑料"
          },
          {
            title: "款式",
            content: "软壳"
          },
          {
            title: "适用人群",
            content: "通用"
          }
        ],
        remarks: {
          goodAnalyse: 90,
          remarksTags: [
            "颜色可人",
            "实惠优选",
            "严丝合缝",
            "极致轻薄",
            "质量没话说",
            "比定做还合适",
            "完美品质",
            "正品行货",
            "包装有档次",
            "不容易发热",
            "已经买第二个",
            "是全覆盖"
          ],
          remarksNumDetail: [2000, 3000, 900, 1],
          detail: [
            {
              username: "p****1",
              values: 3,
              content: "颜色很好看，质量也不错！，还送了个指环，想不到哦！",
              goods: "4.7英寸-深邃蓝",
              create_at: "2018-05-15 09:20"
            },
            {
              username: "13****1",
              values: 5,
              content:
                "手感没的说，是硬壳，后背带有磨砂手感。很不错，很喜欢，还加送了钢化膜，支架环，物超所值，准备再买一个。很满意。物流很快。很愉快的一次购物！",
              goods: "5.5英寸-玫瑰金",
              create_at: "2018-05-13 15:23"
            },
            {
              username: "3****z",
              values: 4.5,
              content: "相当轻薄，店家还送了一大堆配件，*元非常值得！",
              goods: "4.7英寸-玫瑰金",
              create_at: "2018-05-05 12:25"
            },
            {
              username: "gd****c",
              values: 3.5,
              content:
                "就是我想要的手机壳，壳子很薄，手感不错，就像没装手机壳一样，想要裸机手感的赶快下手了。",
              goods: "4.7英寸-中国红",
              create_at: "2018-04-06 16:23"
            },
            {
              username: "r****b",
              values: 4.5,
              content:
                "壳子还不错，送的膜也可以，不过还是感觉膜小了那么一点，屏幕没法完全覆盖。对了，壳子稍微有点硬，可能会伤漆，所以不要频繁取壳就好。",
              goods: "4.7英寸-中国红",
              create_at: "2018-03-15 19:24"
            },
            {
              username: "d****e",
              values: 5,
              content: "磨砂的，相当漂亮，尺寸非常合适！精工细作！",
              goods: "5.5英寸-星空黑",
              create_at: "2018-03-10 10:13"
            }
          ]
        }
      };
      commit("SET_GOODS_INFO", data);
      commit("SET_LOAD_STATUS", false);
    }, 300);
  });
};

// 获取商品列表
export const loadGoodsList = ({ commit }) => {
  commit("SET_LOAD_STATUS", true);
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const data = {
        asItems: [
          {
            img: "static/img/goodsList/item-as-img-1.jpg",
            price: 39.9,
            intro: "SKSK 苹果7/7plus手机壳 iPhone 7 Plus保护套全包硬",
            num: 3140,
            sale: 9000
          },
          {
            img: "static/img/goodsList/item-as-img-2.jpg",
            price: 36.6,
            intro: "狮普 苹果7/7 Plus手机壳 电镀 超薄 全包 防摔 保护外",
            num: 6160,
            sale: 8900
          },
          {
            img: "static/img/goodsList/item-as-img-1.jpg",
            price: 38.6,
            intro: "SKSK 苹果7/7plus手机壳 iPhone 7 Plus保护套全包硬",
            num: 9160,
            sale: 8800
          },
          {
            img: "static/img/goodsList/item-as-img-2.jpg",
            price: 35.6,
            intro: "狮普 苹果7/7 Plus手机壳 电镀 超薄 全包 防摔 保护外",
            num: 6160,
            sale: 7990
          },
          {
            img: "static/img/goodsList/item-as-img-1.jpg",
            price: 38.6,
            intro: "SKSK 苹果7/7plus手机壳 iPhone 7 Plus保护套全包硬",
            num: 9160,
            sale: 7600
          },
          {
            img: "static/img/goodsList/item-as-img-2.jpg",
            price: 35.6,
            intro: "狮普 苹果7/7 Plus手机壳 电镀 超薄 全包 防摔 保护外",
            num: 6160,
            sale: 6900
          }
        ],
        goodsList: [
          {
            img: "static/img/goodsList/item-show-1.jpg",
            price: 36.6,
            intro:
              "SKSK 苹果7/7plus手机壳 iPhone 7 Plus保护套全包硬壳男女磨砂防摔 火影红(苹",
            remarks: 6160,
            shopName: "元亨利配件专营店",
            sale: 9900
          },
          {
            img: "static/img/goodsList/item-show-2.jpg",
            price: 28.0,
            intro:
              "蒙奇奇 苹果6s手机壳磨砂防摔保护套 适用于iphone6/6s/6sPlus/6plus 6/6s 4.7英",
            remarks: 3000,
            shopName: "monqiqi旗舰店",
            sale: 9600
          },
          {
            img: "static/img/goodsList/item-show-3.jpg",
            price: 15.0,
            intro:
              "BIAZE 苹果6/6s手机壳 苹果iphone6s 4.7英寸透明手机套 清爽系列",
            remarks: 2800,
            shopName: "BIAZE官方旗舰店",
            sale: 8900
          },
          {
            img: "static/img/goodsList/item-show-4.jpg",
            price: 29.9,
            intro:
              "慕臣 苹果6s手机壳全包防摔磨砂软保护套男女 适用于iPhone6splus",
            remarks: 9000,
            shopName: "歌乐力手配专营店",
            sale: 8600
          },
          {
            img: "static/img/goodsList/item-show-5.jpg",
            price: 15.0,
            intro:
              "BIAZE 苹果6/6s手机壳 苹果iphone6s 4.7英寸透明手机套 清爽系列",
            remarks: 6160,
            shopName: "BIAZE官方旗舰店",
            sale: 8560
          },
          {
            img: "static/img/goodsList/item-show-6.jpg",
            price: 28.0,
            intro:
              "慕臣 苹果6s手机壳全包防摔磨砂软保护套男女 适用于iPhone6splus",
            remarks: 9006,
            shopName: "歌乐力手配专营店",
            sale: 8530
          },
          {
            img: "static/img/goodsList/item-show-7.jpg",
            price: 15.0,
            intro:
              "BIAZE 苹果6/6s手机壳 苹果iphone6s 4.7英寸透明手机套 清爽系列",
            remarks: 8666,
            shopName: "BIAZE官方旗舰店",
            sale: 8360
          },
          {
            img: "static/img/goodsList/item-show-8.jpg",
            price: 29.9,
            intro:
              "慕臣 苹果6s手机壳全包防摔磨砂软保护套男女 适用于iPhone6splus",
            remarks: 6080,
            shopName: "歌乐力手配专营店",
            sale: 7560
          },
          {
            img: "static/img/goodsList/item-show-1.jpg",
            price: 36.6,
            intro:
              "SKSK 苹果7/7plus手机壳 iPhone 7 Plus保护套全包硬壳男女磨砂防摔 火影红(苹",
            remarks: 6160,
            shopName: "元亨利配件专营店",
            sale: 7360
          },
          {
            img: "static/img/goodsList/item-show-2.jpg",
            price: 28.0,
            intro:
              "蒙奇奇 苹果6s手机壳磨砂防摔保护套 适用于iphone6/6s/6sPlus/6plus 6/6s 4.7英",
            remarks: 3000,
            shopName: "monqiqi旗舰店",
            sale: 6960
          },
          {
            img: "static/img/goodsList/item-show-3.jpg",
            price: 15.0,
            intro:
              "BIAZE 苹果6/6s手机壳 苹果iphone6s 4.7英寸透明手机套 清爽系列",
            remarks: 2800,
            shopName: "BIAZE官方旗舰店",
            sale: 6560
          },
          {
            img: "static/img/goodsList/item-show-4.jpg",
            price: 29.9,
            intro:
              "慕臣 苹果6s手机壳全包防摔磨砂软保护套男女 适用于iPhone6splus",
            remarks: 9000,
            shopName: "歌乐力手配专营店",
            sale: 6360
          },
          {
            img: "static/img/goodsList/item-show-5.jpg",
            price: 15.0,
            intro:
              "BIAZE 苹果6/6s手机壳 苹果iphone6s 4.7英寸透明手机套 清爽系列",
            remarks: 6160,
            shopName: "BIAZE官方旗舰店",
            sale: 5530
          },
          {
            img: "static/img/goodsList/item-show-6.jpg",
            price: 28.0,
            intro:
              "慕臣 苹果6s手机壳全包防摔磨砂软保护套男女 适用于iPhone6splus",
            remarks: 9006,
            shopName: "歌乐力手配专营店",
            sale: 5560
          },
          {
            img: "static/img/goodsList/item-show-7.jpg",
            price: 15.0,
            intro:
              "BIAZE 苹果6/6s手机壳 苹果iphone6s 4.7英寸透明手机套 清爽系列",
            remarks: 8666,
            shopName: "BIAZE官方旗舰店",
            sale: 5260
          },
          {
            img: "static/img/goodsList/item-show-8.jpg",
            price: 29.9,
            intro:
              "慕臣 苹果6s手机壳全包防摔磨砂软保护套男女 适用于iPhone6splus",
            remarks: 6080,
            shopName: "歌乐力手配专营店",
            sale: 3560
          }
        ]
      };
      commit("SET_GOODS_LIST", data);
      commit("SET_LOAD_STATUS", false);
    });
  });
};


// 获取我的余额
export const getWalletMoney = ({ commit }, params) => {
  return new Promise(async(resolve, reject) => {
    const {data,status} = await apiList.getWalletMoney()
    if(status===200){
          commit("SET_MY_BALANCE", data)
          resolve(data)
    }
  });
}

// 获取购物车数量
export const getCartNum = ({ commit }, params) => {
  return new Promise(async(resolve, reject) => {
    const {data:{countnum}={},status} = await apiList.getgetnum()
    if(status===200){
          commit("SHOPPING_CART_NUM", countnum)
          resolve(countnum)
    }
  });
}

export const getCartList =  ({dispatch, commit }, params={page:1}) => {
  return new Promise(async(resolve, reject) => {
        const {status, data} = await apiList.getCartList(params)
        if(status===200){
          const _data = data.map(res=>{
            res.selected =false
            res.goodres.forEach(goods=>{
              goods.selected = false
            })
            return res
          })
          commit("ADD_SHOPPING_CART", _data);
          dispatch('getCartNum')
          resolve(data)
        }
  });
}

// 获取购物车列表
export const addShoppingCart = ({ dispatch }, params) => {
  return new Promise(async(resolve, reject) => {
    const {status,mess} = await apiList.getaddcart(params)
    if(status===200){
          ViewUI.Message.success(mess)
          dispatch('getCartList')
          dispatch('getCartNum')
          resolve()
    }
  });
};

/**
 * 地址列表获取、添加、编辑、删除
 * @param commit
 * @param state
 * @param params  type: get | add | edit | delete
 */
export const actionAddressList = async({commit,state},params={}) =>{
  const {addressList} = state
  const {type='get'} =params
  let actionResult
  if(type==='add'){
    actionResult = await apiList.getAddressadd(params)
  }
  if(type==='edit'){
    actionResult= await apiList.getAddressedit(params)
  }
  if(type==='delete'){
    actionResult= await apiList.getAddressdel(params)
  }
  const {status:aStatus} = actionResult || {}
  if(type!=='get' && aStatus!==200){
      return
  }
  if(!addressList.length || type!=='get'){
    if(!params.page)  {params.page = 1}
    const {status,data,mess}= await apiList.getAddressList(params) || {}
    if(status===200){
      commit('SET_ADDRESS_LIST',data)
      return Promise.resolve({status,data,mess})
    }
  }
}

// 获取用户推荐
export const loadRecommend = ({ commit }) => {
  return new Promise((resolve, reject) => {
    const data = [
      {
        img: "static/img/otherBuy/1.jpg",
        intro: "iPhone7/6s/8钢化膜苹果7Plus全屏全覆盖3D抗蓝",
        tag: "数码产品",
        price: 29.0
      },
      {
        img: "static/img/otherBuy/2.jpg",
        intro: "苹果数据线 苹果iPhoneX/6s/7plus/8充电线",
        tag: "数码产品",
        price: 36.0
      },
      {
        img: "static/img/otherBuy/3.jpg",
        intro: "苹果8/7/6/6s钢化膜 iphone8/7/6s/6钢化玻璃",
        tag: "数码产品",
        price: 19.0
      }
    ];
    commit("SET_RECOMMEND_INFO", data);
  });
};

export const loadAddress = ({ commit }) => {
  return new Promise((resolve, reject) => {
    const address = [
      {
        addressId: "123456",
        name: "Gavin",
        province: "广东省",
        city: "广州市",
        area: "天河区",
        address: "燕岭路633号",
        phone: "152****0609",
        postalcode: "510000"
      },
      {
        addressId: "123458",
        name: "Kevin",
        province: "上海市",
        city: "上海市",
        area: "浦东新区",
        address: "沙新镇",
        phone: "158****0888",
        postalcode: "200120"
      }
    ];
    commit("SET_USER_ADDRESS", address);
  });
};

export const loadShoppingCart = ({ commit }) => {
  return new Promise((resolve, reject) => {
    const data = [
      {
        goods_id: 1529931938150,
        count: 1,
        img: "static/img/goodsDetail/pack/1.jpg",
        package: "4.7英寸-深邃蓝",
        price: 28,
        title: "苹果8/7手机壳iPhone7 Plus保护壳全包防摔磨砂硬外壳"
      }
    ];
    commit("SET_SHOPPING_CART", data);
  });
};

// 添加注册用户
export const addSignUpUser = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    const userArr = localStorage.getItem("users");
    let users = [];
    if (userArr) {
      users = JSON.parse(userArr);
    }
    users.push(data);
    localStorage.setItem("users", JSON.stringify(users));
  });
};

// 用户登录
export const login = ({ commit }, params) => {
  return new Promise(async(resolve, reject) => {
    const {data,status,mess} = await apiList.getPwdLogin(params)
      if(status===200){
        ViewUI.Message.success(mess)
        data.username = params.phone || ''
        commit("SET_TOKEN", data.token);
        router.push("/");
        resolve()
      }
  })
};

// 用户登录
export const getLangList = ({ commit }) => {
  return new Promise(async(resolve, reject) => {
    const {data,status,mess} = await apiList.getLangList()
      if(status===200){
        commit("SET_LANG_LIST", data);
        resolve()
      }
  })
};

export const getUserInfo = ({ commit }) => {
  return new Promise(resolve => {
    apiList.getUserInfo().then(res => {
      if (res.status === 200) {
        commit('SET_USER_LOGIN_INFO', res.data)
        resolve(res.data)
      }
    })
  })
}

// 退出登陆
export const signOut = ({ commit }) => {
  commit("SET_TOKEN", '');
  commit("SET_USER_LOGIN_INFO", {});
  router.push("/login");
};

// 判断是否登陆
export const isLogin = ({ commit }) => {
  const user = localStorage.getItem("loginInfo");
  if (user) {
    commit("SET_USER_LOGIN_INFO", JSON.parse(user));
  }
};

/**
 *
 * @param orderData结构：
 * {
 *   filter:"6" 订单类型
 *   page:"1" 当前订单
 * }
 */
// export const setOrderData = ({ commit,state },params) =>{
//   const {filter,page,data} = params
//   if(data){
//     const {orderData} = state
//     if(orderData[filter]){
//       orderData[filter] = {... orderData[filter],data}
//     }
//     commit("SET_ORDER_DATA", JSON.parse(user));
//   }
// }

export const searchKey = ({commit,state},params) => {
  return new Promise(async(resolve,reject)=>{
    const {status,data} = await apiList.getsearchgoods(params)
    if(status){
      commit('SET_SEARCH_KEY',params.searchKey)
      resolve(data)
    }
  })
}
