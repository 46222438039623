<template>
  <div class="recommend">
    <!-- <Title title="新品推荐" subTitle="诚意推荐 品质商品"> </Title> -->
    <div class="recmmend-content">
      <div
        class="recmmend-item"
        v-for="(item, index) in goodsList"
        :key="index"
      >
        <div class="recmmend-item-info">
          <p class="recmmend-item-info-title">{{ item.intro }}</p>
          <p class="recmmend-item-info-tag" v-if="index === 0">
            {{ item.tag }}
          </p>
          <p class="recmmend-item-info-price">
            <span class="recmmend-price text-danger"
              ><Icon type="social-yen"></Icon>{{ item.currency_symbol }}{{ item.price }}</span
            >
          </p>
        </div>
        <div class="recmmend-item-img">
          <router-link to="/goodsList"><img :src="item.img" /></router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from "../Title";
export default {
  name: "Recommend",
  data() {
    return {};
  },
  components: {
    Title,
  },
  props: {
    goodsList: {
      required: true,
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/common/style/common.scss";
.recommend {
  width: 100%;
  margin: 20px auto;
}
.recmmend-head {
  width: 100%;
  height: 50px;
  background-color: #464a1d;
}
.recmmend-icon {
  width: 68px;
  height: 100%;
  float: left;
}
.recmmend-icon img {
  width: 35px;
  height: 35px;
  margin-top: 6px;
  margin-left: 15px;
  animation-name: shake-clock;
  animation-duration: 0.3s;
  animation-iteration-count: infinite; /*设置无线循环*/
}
/*定义闹钟震动动画关键帧*/
@keyframes shake-clock {
  0% {
    transform: rotate(-8deg);
  }
  50% {
    transform: rotate(8deg);
  }
  100% {
    transform: rotate(-8deg);
  }
}
.recmmend-text {
  width: 300px;
  height: 100%;
  float: left;
}
.recmmend-text .recmmend-title {
  font-size: 22px;
  line-height: 50px;
  color: #fff;
}
.recmmend-text .recmmend-remarks {
  margin-left: 5px;
  font-size: 10px;
  color: #fff;
}
/*倒计时*/
.count-down {
  height: 100%;
  margin-right: 30px;
  line-height: 50px;
  float: right;
}
.count-down-text {
  color: #fff;
}
.count-down-num {
  padding: 3px;
  border-radius: 5px;
  background-color: #f3e7e8;
  font-size: 26px;
  font-weight: bold;
  color: #f90013;
}
.count-down-point {
  font-size: 26px;
  font-weight: bold;
  color: #440106;
}

.recmmend-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.recmmend-item {
  min-height: 200px;
  cursor: pointer;
  background-color: #fff;
  padding: 16px;
  flex-shrink: 1;
  width: 316px;
  height: 270px;
  position: relative;
  &:hover {
    box-shadow: 0 6px 10px 3px #e5e8e8;
    .recmmend-item-info-title {
      color: #e2231a;
    }
  }
  &:first-child {
    display: flex;
    align-items: flex-start;
    flex-shrink: 0;
    flex-basis: 520px;
    &-info {
      &-title {
        @include lineClamp(2);
      }
    }
    .recmmend-item-img {
      width: 200px;
    }
  }
  &:nth-child(n + 1) {
    .recmmend-item-img {
      width: 200px;
      position: absolute;
      top: 80px;
      right: 20px;
    }
  }
  &-first {
    background-color: #e2231a;
    padding: 38px 30px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-title {
      font-size: 28px;
      font-weight: bold;
      color: #fff;
      margin-bottom: 14px;
    }
    &-desc {
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 12px;
      margin-top: 10px;
    }
    &-timer {
      font-size: 16px;
      font-weight: 800;
    }
  }
}
.recmmend-item-img {
  width: 160px;
  height: 160px;
  margin: 0px auto 10px;
  overflow: hidden;
}
.recmmend-item-img img {
  width: 100%;
  height: 100%;
  margin-left: 15px;
  margin-top: 15px;
  transition: margin-top 0.3s;
}

.recmmend-item-info {
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 12px;
  color: #333;
  &-title {
    font-size: 18px;
    font-weight: 600;
    @include lineClamp(1);
    width: 100%;
  }
  &-tag {
    color: #999;
    margin: 10px 0;
    font-size: 14px;
  }
  &-price {
    text-align: left;
    margin-top: 25px;
  }
}
.recmmend-item-info i:first-child {
  font-size: 14px;
}
.recmmend-price {
  margin-right: 5px;
  font-size: 20px;
  font-weight: 600;
  color: #e2231a;
}
</style>
