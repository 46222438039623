<template>
  <div class="coupon-wrap">
    <Title :subTitle="$t('超值优惠')" arrowType="'round'" url="/coupon/CouponList">
      <template #title>
        <h1 class="title">
          <span class="title-end">{{$t('领券中心')}}</span>
        </h1>
      </template>
    </Title>
    <div>
      <ul class="coupon-items">
        <li class="coupon-items-li" v-for="(item, i) in couponList" :key="`coupon-${i}`"  @click="getcoupons(item)">
          <div class="img-wrap">
            <img :src="item.thumb_url" class="coupon-items-li-coverImg" />
          </div>

          <div class="coupon-items-li-bottom">
            <p class="coupon-items-li-bottom-title">
              {{$t('满')}}&nbsp;{{item.man_price}}&nbsp;{{$t('减')}}&nbsp;{{item.dec_price}}
            </p>
            <Button class="coupon-items-li-bottom-btn" shape="circle" type="error" size="small" v-if="item.have==0">{{$t('领券')}}</Button>
            <Button class="coupon-items-li-bottom-btnok" shape="circle" type="error" size="small" v-else>{{$t('已领取')}}</Button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import Title from "../Title";
export default {
  name: "GetCoupon",
  props:{
    couponList:{
      type:Array,
      required:true,
      default:()=>[]
    }
  },
  components: {
    Title,
  },
  methods:{
    getcoupons(item){
      this.$emit('getcoupons',item);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/common/style/common.scss";
.coupon {
  &-wrap {
    @extend .common-wrap;
    padding: 10px 20px 0;
    height: 350px;
  }
  &-items {
    display: flex;
    &-li {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-right: 8px;
      .img-wrap{
        width: 175px;
        height: 175px;
        img{
          width: 100%;
          height: 100%;
        }
      }

      &-bottom{
        width: 174px;
        height: 66px;
        background-image: url('../../../static/img/index/coupon.png');
        background-repeat: no-repeat;
        background-size: cover;
        margin-top: 13px;
        text-align: center;
        padding: 10px;
        display: block;
        box-sizing: border-box;
        &-title{
          font-size: 16px;
          font-weight: 700;
          color: #e93323;
        }
        &-btn{
          font-size: 12px;
          color: #fff;
          width: 70px;
          height: 20px;
          line-height: 20px;
          margin-top: 5px;
          background: #e93323;
          border-radius: 10px;
          display: inline-block;
        }
        &-btnok{
          font-size: 12px;
          color: #fff;
          width: 70px;
          height: 20px;
          line-height: 20px;
          margin-top: 5px;
          background: #e1e1e1;
          border-radius: 10px;
          display: inline-block;
          border: 1px solid #e1e1e1;
        }
      }
    }
    &-coverImg {
      width: 100%;
      height: 185px;
    }
  }
}
.title {
  font-size: 0;
  & > span {
    font-size: 24px;
    font-weight: 800;
  }
  &-front {
    color: #333;
  }
  &-end {
    display: block;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #e2231a;
  }
}

</style>
