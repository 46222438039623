let emojiBaseUrl = `https://cbec.woshopx.net/static/layui/images/face/`

let emoji = {
  "face[微笑]": { file: "0.gif" },
  "face[嘻嘻]": { file: "1.gif" },
  "face[哈哈]": { file: "2.gif" },
  "face[可爱]": { file: "3.gif" },
  "face[可怜]": { file: "4.gif" },
  "face[挖鼻]": { file: "5.gif" },
  "face[吃惊]": { file: "6.gif" },
  "face[害羞]": { file: "7.gif" },
  "face[挤眼]": { file: "8.gif" },
  "face[闭嘴]": { file: "9.gif" },
  "face[鄙视]": { file: "10.gif" },
  "face[爱你]": { file: "11.gif" },
  "face[泪]": { file: "12.gif" },
  "face[偷笑]": { file: "13.gif" },
  "face[亲亲]": { file: "14.gif" },
  "face[生病]": { file: "15.gif" },
  "face[太开心]": { file: "16.gif" },
  "face[白眼]": { file: "17.gif" },
  "face[右哼哼]": { file: "18.gif" },
  "face[左哼哼]": { file: "19.gif" },
  "face[嘘]": { file: "20.gif" },
  "face[衰]": { file: "21.gif" },
  "face[委屈]": { file: "22.gif" },
  "face[吐]": { file: "23.gif" },
  "face[哈欠]": { file: "24.gif" },
  "face[抱抱]": { file: "25.gif" },
  "face[怒]": { file: "26.gif" },
  "face[疑问]": { file: "27.gif" },
  "face[馋嘴]": { file: "28.gif" },
  "face[拜拜]": { file: "29.gif" },
  "face[思考]": { file: "30.gif" },
  "face[汗]": { file: "31.gif" },
  "face[困]": { file: "32.gif" },
  "face[睡]": { file: "33.gif" },
  "face[钱]": { file: "34.gif" },
  "face[失望]": { file: "35.gif" },
  "face[酷]": { file: "36.gif" },
  "face[色]": { file: "37.gif" },
  "face[哼]": { file: "38.gif" },
  "face[鼓掌]": { file: "39.gif" },
  "face[晕]": { file: "40.gif" },
  "face[悲伤]": { file: "41.gif" },
  "face[抓狂]": { file: "42.gif" },
  "face[黑线]": { file: "43.gif" },
  "face[阴险]": { file: "44.gif" },
  "face[怒骂]": { file: "45.gif" },
  "face[互粉]": { file: "46.gif" },
  "face[心]": { file: "47.gif" },
  "face[伤心]": { file: "48.gif" },
  "face[猪头]": { file: "49.gif" },
  "face[熊猫]": { file: "50.gif" },
  "face[兔子]": { file: "51.gif" },
  "face[ok]": { file: "52.gif" },
  "face[耶]": { file: "53.gif" },
  "face[good]": { file: "54.gif" },
  "face[NO]": { file: "55.gif" },
  "face[赞]": { file: "56.gif" },
  "face[来]": { file: "57.gif" },
  "face[弱]": { file: "58.gif" },
  "face[草泥马]": { file: "59.gif" },
  "face[神马]": { file: "60.gif" },
  "face[囧]": { file: "61.gif" },
  "face[浮云]": { file: "62.gif" },
  "face[给力]": { file: "63.gif" },
  "face[围观]": { file: "64.gif" },
  "face[威武]": { file: "65.gif" },
  "face[奥特曼]": { file: "66.gif" },
  "face[礼物]": { file: "67.gif" },
  "face[钟]": { file: "68.gif" },
  "face[话筒]": { file: "69.gif" },
  "face[蜡烛]": { file: "70.gif" },
  "face[蛋糕]": { file: "71.gif" }
}

const getTitle = e => {
  const re = /\[[^\)]+\]/g
  let encode = e.match(re)[0]
  return encode.substring(1, encode.length - 1)
}

const emojiList = Object.keys(emoji).map(v => {
  return {
    name: emoji[v].file.split('.')[0],
    value: v,
    title: getTitle(v),
    src: emojiBaseUrl + emoji[v].file
  }
})

export default {
  emojiList
}