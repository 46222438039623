/**
 * @Description: Shopee
 * @Author: Parker
 * @Copyright: 武汉一一零七科技有限公司©版权所有
 * @Link: www.wo-shop.net
 * @Contact: QQ:2487937004
 */

import ViewUI from "view-design";
import { SERVERTAPI } from "../config/index";
import Router from "@/router"
import axios from "axios"
import store from "@/vuex/store"
const instance = axios.create({
  baseURL: SERVERTAPI,
  timeout: 50000,
  headers: {}
});

instance.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  config.token = store.state.token || '';
  config.device_token = "";
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

instance.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  const {status,data} = response
  if (status === 200) {
    switch (data.status) {
      case 400:
        if (data.mess === '请先登录' || data.mess === '登录状态失效，请重新登录') {
          ViewUI.Message.error('登录失效，请重新登录')
          store.dispatch('signOut')
        } else {
          ViewUI.Message.error(data.mess)
        }
        // Router.push({path: '/Login'})
        break;
    }
      return Promise.resolve(data);
  } else {
    ViewUI.Message.error(data.mess)
    return Promise.reject(response);
  }
}, error=> {
  // 对响应错误做点什么
  ViewUI.Message.error(error)
  return Promise.reject(error);
});



export default instance;
