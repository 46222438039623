<template>
  <lemon-message-basic v-bind="$attrs">
    <template #content>
      <div class="goods-item" @click="toDetail">
        <img :src="goodsInfo.goodsThumb" />
        <div class="goods-item__right">
          <h2>{{ goodsInfo.goodsName }}</h2>
          <p>¥{{ goodsInfo.shopPrice }}</p>
        </div>
      </div>
    </template>
  </lemon-message-basic>
</template>

<script>
  export default {
    name: 'lemonMessageGoods',
    
    inheritAttrs: false,

    data () {
      return {
        goodsInfo: {}
      }
    },

    created () {
      this.goodsInfo = this.format(this.$attrs.message.content)
    },

    methods: {
      format (goods) {
        return JSON.parse(goods.substr(5))[0]
      },

      toDetail () {
        window.open(`${location.origin}/pc#/goodsDetail?goodsId=${this.goodsInfo.goodsId}`)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .goods-item {
    display: flex;
    align-items: center;
    width: 380px;
    line-height: 1.3;
    cursor: pointer;
    transition: all ease 0.5s;

    &:hover {
      opacity: 0.6;
    }

    img {
      width: 90px;
      height: 90px;
      margin-right: 10px;
      border-radius: 6px;
      object-fit: cover;
    }

    .goods-item__right {
      flex: 1;
      
      h2 {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 2;
        font-size: 14px;
      }

      p {
        font-size: 18px;
        font-weight: bold;
        color: #e2231a;
      }
    }
  }
</style>
