<template>
  <div class="container">
    <!-- <HomeScreen></HomeScreen> -->
    <Search></Search>
    <SideBar></SideBar>
    <HomeNav ref="homeNav" :bannerList="bannerList" ></HomeNav>
    <!-- 商品显示区域 -->
    <div class="w">
      <!-- 秒杀 -->
      <Seckill :seckillData="seckillData" />
      <!-- 推荐 -->
      <Recommend :goodsList="recommend" />
      <!-- 领券中心&&新品首发 -->
      <Row :gutter="16">
        <Col span="12"> <GetCoupon :couponList="couponList" @getcoupons="getcoupons"/></Col>
        <Col span="12"> <NewProduct :newGoods="newGoods"/></Col>
      </Row>

      <!-- 推荐单品&& 品牌 && 热门 -->
      <Row :gutter="16" class="mt-20">
        <Col span="8"> <RecommendSingle :goodsList="recommendSingle"/></Col>
        <Col span="8"> <Brand :recommendShops="recommendShops"/></Col>
        <Col span="8"> <Hot :goodsList="hotBrand"/></Col>
      </Row>
      <div class="recommend-title-wrap">
        <img src="../../static/img/index/title-left.png">
        <h3 class="recommend-title">{{$t('为你推荐')}}</h3>
        <img src="../../static/img/index/title-right.png">
      </div>
      <Row class="like-wrap">
        <Col span="24">
          <GoodsList :goodsList="likeGoodsList"></GoodsList>
        </Col>
      </Row>
    </div>
  </div>
</template>

<script>
import store from "@/vuex/store";
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "Index",
  data() {
    return {
      setIntervalObj: null,
      bannerList:[], // banner
      primaryNav:[], // 一级分类
      recommendShops:[], // 品牌好店
      couponList:[], // 领券中心
      seckillData:{}, // 秒杀
      newGoods:{}, // 新品首发
      likeGoodsList:[],
      recommendSingle:[],
      hotBrand:[],
    };
  },

  computed: {
    ...mapState(["seckills", "computer", "eat", "recommend"]),
    ...mapGetters(["seckillsHours", "seckillsMinutes", "seckillsSeconds"]),
  },
  components: {
    Search:()=>import('@/components/Search'),
    HomeNav:()=>import('@/components/nav/HomeNav'),
    Seckill:()=>import('@/components/home/Seckill'),
    Recommend:()=>import('@/components/home/Recommend'),
    GetCoupon:()=>import('@/components/home/GetCoupon'),
    NewProduct:()=>import('@/components/home/NewProduct'),
    RecommendSingle:()=>import('@/components/home/RecommnendSingle'),
    Brand:()=>import('@/components/home/Brand'),
    Hot:()=>import('@/components/home/Hot'),
    GoodsList:()=>import('@/components/home/goodsList/GoodsList'),
    Title:()=>import('@/components/Title'),
    SideBar:()=>import('@/components/SideBar'),
    HomeScreen:()=>import('@/components/home/HomeScreen'),
  },
  created(){
    this.init()
  },
  // mounted() {
  //   const father = this;
  //   this.setIntervalObj = setInterval(function () {
  //     father.REDUCE_SECKILLS_TIME();
  //   }, 1000);
  // },
  destroyed() {
    clearInterval(this.setIntervalObj);
  },
  methods:{
    ...mapMutations(["REDUCE_SECKILLS_TIME"]),
    ...mapActions(['getCartNum','getLangList']),
    init(){
      this.getAdByTag()
      this.getPcCategoryList()
      this.getCartNum()
      this.getRecommendShops()
      this.getHomeCouponList()
      this.getPcIndexShowcase()
      this.getPcGoodsList()
      this.getLangList()
      this.getPcCategoryList()
      this.getMenuList()
      Promise.all([this.getPcIndexGoodsHome('is_recommend'),this.getPcIndexGoodsHome('is_hot')]).then(res=>{
        this.$set(this,'recommendSingle',res[0])
        this.$set(this,'hotBrand',res[1])
      })
    },
    async getPcCategoryList(){
     const {status,data} = await this.$http.getPcCategoryList()
     if(status===200){
        this.$nextTick(()=>{
          this.$refs.homeNav.navList = data
        })
     }
    },
   async getMenuList(){
      const {status,data} = await this.$http.getMenuList()
      if(status===200){
        this.$nextTick(()=>{
          this.$refs.homeNav.navData = data.menu_list
        })
      }
    },
    getAdByTag(){
      this.$http.getAdByTag({
        tag:'pc_index_banner'
      }).then(Res=>{
        const {status,data} = Res
        if(status===200){
          this.$nextTick(()=>{
            this.$set(this,'bannerList',data)
          })
        }else{
          console.log('banner数据获取异常');
        }
      })
    },


    getRecommendShops(){
      this.$http.getRecommendShops().then(Res=>{
        const {status,data} = Res
        if(status===200){
          this.$nextTick(()=>{
            this.recommendShops = data
          })
        }else{
          console.log('banner数据获取异常');
        }
      })
    },
    getHomeCouponList(){
      this.$http.getHomeCouponList().then(res=>{
        const {status,data} = res
        if(status===200){
          this.$set(this,'couponList',data)
        }else{
          console.log('home领券数据获取异常');
        }
      })
    },
    getPcIndexShowcase(){
      this.$http.getPcIndexShowcase().then(res=>{
        const {status,data:{new_goods,seckill}} = res
        if(status===200){
          this.$set(this,'seckillData',seckill)
          this.$set(this,'newGoods',new_goods)
        }else{
          console.log('home领券数据获取异常');
        }
      })
    },
    getPcGoodsList(){
      this.$http.getPcGoodsList({page:1}).then(res=>{
        const {status,data} = res
        if(status===200){
          this.$set(this,'likeGoodsList',data)
        }else{
          console.log('home领券数据获取异常');
        }
      })
    },
    getcoupons(item){
      console.log(item);
      this.$http.getcoupons({coupon_id:item.id,shop_id:item.shop_id}).then(res => {
        if (res.status === 200) {
          this.getHomeCouponList();
        }
      })
    },
    getPcIndexGoodsHome(tag){
     return this.$http.getPcIndexGoodsHome({tag,num:3}).then(res=>{
        const {status,data} = res
        if(status===200){
          return Promise.resolve(data)
        }else{
          console.log('home领券数据获取异常');
        }
      })
    },
  },
  store,
};
</script>

<style lang="scss" scoped>
.container{
  background-color: #f4f4f4;
}
.content {
  width: 1280px;
  margin: 0px auto;
}
/*****************************秒杀专栏结束*****************************/

.recommend-title-wrap{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  margin: 30px auto;
  .recommend-title{
    display: block;
    position: relative;
    // width: 150px;
    height: 45px;
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    line-height: 45px;

    overflow: hidden;
    color: #333;
  }

  img{
    width: 25px;
    height: 20px;
  }
}


/*****************************商品专栏开始*****************************/
.item-class {
  width: 100%;
  height: 470px;
  margin-top: 15px;
  background-color: #fff;
}
.item-class-head {
  width: 100%;
  height: 50px;
}
.item-class-eat-head {
}
.item-class-head ul {
  list-style: none;
  float: right;
  margin-right: 30px;
  line-height: 50px;
}
.item-class-head a {
  padding: 6px;
  margin-left: 15px;
  font-size: 12px;
  text-decoration: none;
  color: #666;
}
.item-class-eat-head a {
}
.item-class-head a:hover {
  color: #e1251b;
  border-bottom: 2px solid #e1251b;
  font-weight: 500;
}
.item-class-head li {
  float: left;
}
.item-class-title {
  font-size: 25px;
  line-height: 50px;
  color: #333;
  margin-left: 15px;
  font-weight: 500;
}
.item-class-content {
  width: 49%;
  cursor: pointer;
  border-right: 1px solid #e7e3e7;
  margin-left: 0.9%;
  /*background-color: #cff;*/
  float: left;
}
.item-class-content:nth-child(odd) {
  border: none;
}
.item-content-top {
  width: 100%;
  height: 260px;
  display: flex;
}
.item-big-img {
  height: 260px;
  width: 50%;
  flex-shrink: 0;
}
.item-big-img img {
  margin-left: 0px;
  transition: margin-left 0.3s;
}
.item-big-img:hover img {
  margin-left: -15px;
  transition: margin-left 0.3s;
}
.item-four-img {
  margin-left: 8px;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}
.item-four-detail img {
  margin-left: 0px;
  transition: margin-left 0.3s;
}
.item-four-detail:hover img {
  margin-left: -6px;
  transition: margin-left 0.3s;
}
.item-four-detail {
  width: 50%;
  height: 130px;
  margin-left: -1px;
  float: left;
}

.item-four-detail-text {
  width: 50px;
  margin-left: 5px;
  margin-top: 15px;
  float: left;
}
.item-four-detail-img {
  margin: 4px;
}
.item-four-detail-img img {
  margin-left: 5px;
  width: 100%;
}
.pt_bi_tit {
  font-weight: bold;
  font-size: 12px;
  color: #a53b09;
}
.pt_bi_tit-eat {
  color: #ecb127;
}
.pt_bi_promo {
  color: #d86c10;
}
.item-content-bottom {
  width: 100%;
  margin-top: 8px;
  display: flex;
}
.item-content-bottom-img {
  width: 200px;
  margin-right: 8px;
  overflow: hidden;
  float: left;
}
.item-content-bottom-img img {
  margin-left: 0px;
  transition: margin-left 0.3s;
}
.item-content-bottom-img:hover img {
  margin-left: -15px;
  transition: margin-left 0.3s;
}
.like-wrap{
  padding-bottom:30px
}
/*****************************商品专栏结束*****************************/
</style>
