<template>
  <div class="shop-box">
    <div class="shop-container w">
      <div class="shop-wrap">
        <div class="shop-title-content">
          <Avatar size="80" :src="shopInfo.logo"/>
          <router-link :to="{path:'/merchant',query:{goodsId:shopInfo.id}}">
          <div class="shop-title-content-name">
            <p class="shop-title-content-name-name">
            {{ shopInfo.shop_name }}
            <span class="shop-title-content-focus" @click="focus(shopInfo.coll_shops)">{{!shopInfo.coll_shops?$t('关注'):$t('已关注')}}</span>
          </p>
          <p class="shop-title-content-name-desc">
            {{ shopInfo.shop_desc }}
          </p>
          <p class="shop-title-content-name-desc">
           {{ `销量： ${shopInfo.deal_num}` }}
          </p>
          </div>
        </router-link>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
let isFollow = false
export default {
  name: "ShopHeader",
  props: {
    shopInfo: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
    };
  },
  methods:{
    	/**
			 * 关注
			 */
    focus(coll_shops) {
      this.$emit('focus',coll_shops);
    },
    getshopcoll(){
      const {id:shop_id} = this.shopInfo
      this.$http.getshopcoll({shop_id}).then(Res=>{

     })
  },
}
}
</script>

<style lang="scss" scoped>
/* 店铺介绍 */
.shop-box {
  width: 100%;
  height: 130px;
  background-color: #e1251b;
}

.shop-container {
  height: 100%;
  margin: 0px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.shop-wrap {
  display: flex;
  flex-direction: row;
  border-radius: 3px;
  padding:10px 20px
}

.shop-title-icon {
  font-size: 46px;
}

.shop-title-content {
  padding-top: 8px;
  margin-left: 15px;
  display: flex;
  &-name {
    color: #fff;
    margin: 4px 10px;

    &-name {
      font-size: 24px;
    }
    &-desc {
      font-size: 14px;
      color: rgba($color: #fff, $alpha: 0.7);
    }
  }
  &-focus{
    background:linear-gradient(45deg, #ff746d, white);
    color:#e1251b;
    font-size: 14px;
    font-weight: 500;
    padding:4px 14px;
    border-radius: 100px;
    margin-left: 20px;
  }
}

.shop-title-content a {
  color: #fff;
}

.shop-another-item {
  display: flex;
  flex-direction: row;
}

.shop-another-item-detail {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 15px;
}

.shop-another-item-img {
  height: 80px;
  border-radius: 40px;
  overflow: hidden;
}

.shop-another-item-img img {
  width: 80px;
}

.shop-anoter-item-intro {
  margin-left: 15px;
}
</style>
