// 各项全局常量  cdn静态资源配置
const APPNAME = 'Allegro'; //app名称
const LIVESOCKET = 'wss://api.allegropls.vip:8273/'; // 聊天sokect地址，服务器端需要放行8273端口
let SERVERTAPI = 'https://ht.allegropls.vip/'; // 线上环境
const STATICURL = 'https://ht.allegropls.vip/front_static/'; //静态资源地址 ***正式上线必须换成自己的***
const SECRETKEY = 'yiling6670238160ravntyoneapp7926'; // api密钥约定，搭配md5加密在请求时生成api_token，对应后端数据表sp_secret的client_secret字段值

if (process.env.NODE_ENV === 'development') {
  // SERVERTAPI= 'https://dev.wo-shop.net/';  // 测服地址
  // SERVERTAPI = 'https://kjshop.api.enshitc.com/';
  // SERVERTAPI = 'http://192.168.0.139/';
}
localStorage.setItem('baseUrl', SERVERTAPI);

export {APPNAME, LIVESOCKET, SERVERTAPI, STATICURL, SECRETKEY};
