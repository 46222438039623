<template>
  <div class="seckill" id="Seckill">
    <!-- 内容 -->
    <div class="seckill-content">
      <div class="seckill-item seckill-item-first">
        <h1 class="seckill-item-first-title">{{seckillData.title}}</h1>
        <Icon type="md-timer" color="#fff" size="68" />
        <div class="seckill-item-first-desc">
          <p>
            <span class="seckill-item-first-timer">12:00</span> {{$t('点场')}}
            <!-- <span>距结束</span> -->
          </p>
        </div>
      </div>
      <div class="seckill-item" v-for="(item) in seckillData &&seckillData.list" :key="item.id" @click="$utils.goGoodsDeatil(item.goods_id)">
        <div class="seckill-item-img">
         <img :src="item.thumb_url" />
        </div>
        <div class="seckill-item-info">
          <p class="seckill-item-info-title">{{ item.goods_name }}</p>
          <p class="seckill-item-info-price">
            <span class="seckill-price text-danger"><Icon type="social-yen"></Icon>{{ item.currency_symbol }}{{ item.shop_price }}</span>
            <span class="seckill-price-old"><s>{{ item.currency_symbol }}{{ item.price }}</s></span>
          </p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "Seckill",
  props: {
    seckillData: {
      required: true,
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/common/style/common.scss";
.seckill {
  width: 100%;
  margin: 20px auto;
  background-color: #fff;
}
.seckill-head {
  width: 100%;
  height: 50px;
  background-color: #464a1d;
}
.seckill-icon {
  width: 68px;
  height: 100%;
  float: left;
}
.seckill-icon img {
  width: 35px;
  height: 35px;
  margin-top: 6px;
  margin-left: 15px;
  animation-name: shake-clock;
  animation-duration: 0.3s;
  animation-iteration-count: infinite; /*设置无线循环*/
}
/*定义闹钟震动动画关键帧*/
@keyframes shake-clock {
  0% {
    transform: rotate(-8deg);
  }
  50% {
    transform: rotate(8deg);
  }
  100% {
    transform: rotate(-8deg);
  }
}
.seckill-text {
  width: 300px;
  height: 100%;
  float: left;
}
.seckill-text .seckill-title {
  font-size: 22px;
  line-height: 50px;
  color: #fff;
}
.seckill-text .seckill-remarks {
  margin-left: 5px;
  font-size: 10px;
  color: #fff;
}
/*倒计时*/
.count-down {
  height: 100%;
  margin-right: 30px;
  line-height: 50px;
  float: right;
}
.count-down-text {
  color: #fff;
}
.count-down-num {
  padding: 3px;
  border-radius: 5px;
  background-color: #f3e7e8;
  font-size: 26px;
  font-weight: bold;
  color: #f90013;
}
.count-down-point {
  font-size: 26px;
  font-weight: bold;
  color: #440106;
}

.seckill-content {
  width: 100%;
  display: flex;
}
.seckill-item {
  width: calc(100% / 5);
  min-height: 280px;
  cursor: pointer;
  &:hover {
    .seckill-item-info-title {
      color: #e2231a;
    }
  }
  &-first {
    background-image: url("../../../static/img/index/seckill/seckill.png");
    background-color: #e2231a;
    padding: 38px 30px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-title {
      font-size: 28px;
      font-weight: bold;
      color: #fff;
      margin: 14px auto;
    }
    &-desc {
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 12px;
      margin-top: 10px;
    }
    &-timer {
      font-size: 16px;
      font-weight: 800;
    }
  }
}
.seckill-item-img {
  width: 180px;
  height: 180px;
  margin: 0px auto 10px;
  overflow: hidden;
}
.seckill-item-img img {
  width: 100%;
  height: 100%;
  margin-left: 15px;
  margin-top: 15px;
  transition: margin-top 0.3s;
}

.seckill-item-info {
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 12px;
  color: #282828;
  &-title {
    font-size: 14px;
    margin-top: 10px;
    @include lineClamp(1);
  }
  &-price {
    text-align: center;
    margin-top: 12px;
  }
}
.seckill-item-info i:first-child {
  font-size: 14px;
}
.seckill-price {
  margin-right: 5px;
  font-size: 18px;
  font-weight: bold;
  color: #e2231a;
  &-old{
    color: #a3a3a3;
  }
}
</style>
