import { render, staticRenderFns } from "./Brand.vue?vue&type=template&id=7bf160e7&scoped=true"
import script from "./Brand.vue?vue&type=script&lang=js"
export * from "./Brand.vue?vue&type=script&lang=js"
import style0 from "./Brand.vue?vue&type=style&index=0&id=7bf160e7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bf160e7",
  null
  
)

export default component.exports