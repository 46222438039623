<template>
  <div class="goodsItem" @click="goGoodsDeatil(item.id)">
    <div style="display:flex;justify-content:center">
        <img class="goodsItem-cover" :src="item.thumb_url" fit="contain" />
    </div>
  
    <h4 class="goodsItem-title">{{ item.goods_name }}</h4>
    <div class="goodsItem-bottom">
      <span class="goodsItem-bottom-price">{{ item.currency_symbol }}{{ item.min_price }}</span>
      <span class="goodsItem-bottom-right">{{ item.currency_symbol }}{{ item.zs_price }}</span>
    </div>
  </div>
</template>
<script>
import {goGoodsDeatil} from "@/common/utils"
export default {
  name: "GoodsItem",
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  methods:{
    goGoodsDeatil
  }
};
</script>

<style lang="scss" scoped>
@import "@/common/style/common.scss";
.goodsItem {
  padding: 15px;
  background-color: #fff;
  cursor: pointer;

  &-cover {
    
    height: 200px;
  }
  &-title {
    @include lineClamp(2);
  }
  &-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 6px;
    &-price {
      font-size: 20px;
      font-weight: bold;
      color: #e2231a;
    }
  }
}
</style>
