<template>
  <div class="new-wrap" id="NewProduct">
    <Title :subTitle="$t('美好新生活')" arrowType="'round'" url="/productGoods/goodsList?tag=new">
      <template #title>
        <h1 class="title">
          <span class="title-end">{{$t('新品首发')}}</span>
        </h1>
      </template>
    </Title>
    <div>
      <ul class="new-items">
        <li class="new-items-li" v-for="(item, i) in newGoods && newGoods.list" :key="`new-${i}`"
          @click="goGoodsDeatil(item.id)">
          <div class="img-wrap">
            <img :src="item.thumb_url" class="new-items-li-coverImg" />
          </div>

          <div class="new-items-li-bottom">
            <p class="new-items-li-bottom-title">
              {{ item.goods_name }}
            </p>
            <div>
              <Tag checkable color="warning">{{$t('优惠价')}}</Tag><br/>
              <span class="new-items-li-bottom-price">{{ item.currency_symbol }}{{item.shop_price}}</span>
            </div>
            <p class="new-items-sale" v-if="item.sale_num">{{ `${$t('销量')}${item.sale_num}${$t('件')}` }}</p>
          </div>
        </li>

      </ul>
    </div>
  </div>
</template>
<script>
  import Title from "../Title";
  import {
    goGoodsDeatil
  } from "@/common/utils"
  export default {
    name: "GetCoupon",
    props: {
      newGoods: {
        type: Object,
        default: () => {},
        required: true
      }
    },
    data() {
      return {

      };
    },
    methods: {
      goGoodsDeatil,

    },
    components: {
      Title,
    },
  };
</script>
<style lang="scss" scoped>
  @import "@/common/style/common.scss";

  .new {
    &-wrap {
      @extend .common-wrap;
      padding: 10px 20px 0;
      height: 350px;
    }

    &-items {
      display: flex;

      .img-wrap {
        width: 175px;
        height: 175px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      &-li {
        display: flex;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
        margin-right: 8px;

        &-bottom {
          padding: 10px 5px 20px;

          &-title {
            margin-bottom: 6px;
            @include lineClamp(1);
          }

          &-price {
            font-size: 18px;
            color: #e2231a;
            font-weight: bold;
            padding-top: 4px;
            display: inline-block;
          }
        }

        &-coverImg {
          // width: 100%;
          height: 185px;
        }
      }

      &-sale {
        font-size: 12px;
        color: #999;
        margin-top: 4px;
      }
    }
  }

  .title {
    font-size: 0;

    &>span {
      display: block;
      max-width: 200px;
      font-size: 24px;
      font-weight: 800;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-front {
      color: #333;
    }

    &-end {
      display: block;
      max-width: 200px;
      font-size: 24px;
      font-weight: 800;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #e2231a;
    }
  }
</style>
