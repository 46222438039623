<template>
  <div class="shop-nav-box">
    <div class="shop-nav-container w">
      <ul>
        <li>
          <router-link :to="{path:'/merchant',query:{shop_id:shop_id}}">{{ '店铺首页' | T }}</router-link>
        </li>
        <li v-for="(item, index) in cateres" :key="index" @click="cateClick(item)">
          <router-link :to="{path:'/merchant',query:{shcate_id:item.id,shop_id}}">{{ item.cate_name }}</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "GoodsDetailNav",
  props:{
    cateres:{
      type:Array,
      default:()=>[]
    },
    shop_id: {
      type: [String, Number],
      default: '',
    }
  },
  methods:{
    cateClick(item){

    }
  }
};
</script>

<style scoped>
.shop-nav-box {
  height: 38px;
  background-color: #333333 !important;
}

.shop-nav-container {
  height: 100%;
  margin: 0px auto;
}

.shop-nav-container ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
}

.shop-nav-container li {
  float: left;
  margin-right: 30px;
  line-height: 35px;
}

.shop-nav-container a {
  text-decoration: none;
  color: rgb(233, 224, 224);
  font-weight: bold;
}
</style>
