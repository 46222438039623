<template>
  <div class="wrap" id="Search">
    <div class="w search">
      <div class="logo-wrap">
        <router-link to="/" >
          <img style="width:120px" :src="logo" alt="" />
        </router-link>
      </div>
      <div class="search-wrap">
        <div class="search-line">
          <i-input
            v-model="searchKey"
            size="small"
            class="search-bar"
            :placeholder="'请输入商品关键词' | T"
          >
          </i-input>
          <div class="search-btn" @click="searchKeyWord">
            <Icon type="ios-search" color="#fff" size="24"/>
          </div>
        </div>
        <!-- <ul class="promotion-wrap">
          <li v-for="(item, i) in promotionTags">
            <a class="promotion-tag">{{ item }}</a>
          </li>
        </ul> -->
      </div>
      <div class="cart" @click="goCart" id="navCart">
        <Icon type="ios-cart-outline" size="22" color="#e1251b"/>
        <span class="cart-text">{{ '购物车' | T }} ({{ cartNum }})</span>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex';
import { STATICURL,APPNAME } from '@/config/index'
export default {
  name: 'Search',
  data () {
    return {
      searchData: '',
      searchKey: '',
      promotionTags: ['新款连衣裙', '四件套', '时尚女鞋', '短裤'],
      logo: STATICURL + 'images/logo/logoWoshop300.png',
      appName: APPNAME
    };
  },
  computed: {
    ...mapState(['cartNum'])
  },
  methods: {
    ...mapMutations(['SET_SEARCH_KEY']),
    closeTags (index) {
      this.promotionTags.splice(index, 1);
    },
    selectTags (index) {
      this.searchData = this.promotionTags[index];
    },
    searchKeyWord () {
      const {searchKey} = this;
      this.SET_SEARCH_KEY(searchKey);
      if (!searchKey || this.$router.history.current.name === 'GoodsList') return;
      this.$router.push({
        path: '/goodsList',
        query: {key: searchKey}
      });
    },
    goCart () {
      this.$router.push({
        path: '/Cart',
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  background: #fff;
  // border: 1px solid #ddd;
  width: 100%;
}

.search {
  display: flex;
  padding: 35px 0;
  position: relative;
  justify-content: space-between;

  &-bar {
    width: 540px;
    border: 2px solid #e2231a;
    flex: 1
  }

  &-line {
    display: flex;
  }

  &-btn {
    background-color: #e2231a;
    width: 58px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.container {
  margin: 20px 0px 0 60px;
}

.cart {
  display: flex;
  align-items: center;
  border: 1px solid #eee;
  margin-left: 20px;
  padding: 0 20px;
  height: 36px;
  cursor: pointer;
  transition: all 0.6s;

  &-text {
    font-size: 14px;
    margin-left: 10px;
    color: #e1251b;
  }

  &:hover {
    border: 1px solid #e1251b;
  }
}

.nav-item {
  position: absolute;
  bottom: 0;
  left: 300px;
  display: flex;

  &-li {
    padding: 10px;
    font-size: 14px;

    &-a {
      color: #333;
    }
  }
}

.promotion {
  &-wrap {
    display: flex;
    margin-top: 5px;
  }

  &-tag {
    font-size: 12px;
    color: #999;
    margin-right: 10px;
  }
}

.logo-wrap {
  width: 240px;
  display: flex;
  align-items: center;

  a {
    display: block;
  }

  &-img {
    width: 160px;
  }
}

::v-deep .ivu-input {
  height: 32px;
  border: none;
}

::v-deep .ivu-input-group-append {
  background-color: #e2231a;
  border: none;
}
</style>
