<template>
  <div>
      <component :is="theme" :coupon="coupon"></component>
  </div>
</template>

<script>
  import Theme1 from './Theme1.vue';
export default {
  name:'CouponItem',
  components:{
    Theme1
  },
  props:{
    theme:{
      default: () => {
        return Theme1
      }
    },
    coupon:{
      type:Array,
      // required:true,
      default:[]
    }
  },
}
</script>

<style>

</style>
