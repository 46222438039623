import ViewUI from "view-design";
import router from "@/router"
/**
 * 跳转到商品详情页
 * @param {goodsId} 商品id
 */
const goGoodsDeatil = function(goodsId){
  router.push({path:'/goodsDetail',query:{goodsId}})
}

/**
 * 跳转到订单详情页
 * @param {order_num} 订单id
 */
 const goOrderDetail = function(order_num){
  router.push({path:'/OrderDetail',query:{order_num}})
}

const goMerchant = (shop_id, shcate_id )=>{
  router.push({path:'/Merchant',query:{shop_id, shcate_id }})
}

const clipboard = (content)=>  {
  const input = document.createElement("input"); // 创建input对象
  input.value = content; // 设置复制内容
  document.body.appendChild(input); // 添加临时实例
  input.select(); // 选择实例内容
  document.execCommand("Copy"); // 执行复制
  document.body.removeChild(input); // 删除临时实例
  ViewUI.Message.success('复制成功！');
}

const scrollIntoView =(id)=>{
  document.getElementById(id).scrollIntoView({
    behavior: "smooth", // 默认 auto
  })
}

export {
  goGoodsDeatil,
  goOrderDetail,
  goMerchant,
  clipboard,
  scrollIntoView
}
