<template>
  <div :class="{ 'address-box': true, 'address-box-active': active }" @click="itemClick">
    <span class="label-default" v-if="addressItem.default">{{$t('默认')}}</span>
    <p class="address-box-name" :title="addressItem.contacts">
      {{ addressItem.contacts || "" }}
    </p>
    <p class="address-box-phone">{{ addressItem.phone || "" }}</p>
    <p class="address-box-address">{{`${addressItem.province}${addressItem.city}${addressItem.address}` }}</p>
  </div>
</template>
<script>
export default {
  name: "AddressItem",
  props: {
    addressItem: {
      type: Object,
      required: true,
      default: () => {},
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    itemClick(){
      this.$emit('addressItemClick',{...this.addressItem})
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/common/style/common.scss";
.address {
  &-box {
    width: 260px;
    border: 1px solid #eee;
    padding: 30px 20px;
    margin: 0 20px 20px 0;
    transition: all 0.5s;
    position: relative;
    cursor: pointer;
    &-active {
      border: 1px solid #e2231a;
    }
    &-name {
      @include lineClamp(2);
    }
    &-address {
      @include lineClamp(2);
    }
    .label-default {
      position: absolute;
      top: 0;
      right: 0;
      display: inline-block;
      padding: 0px 10px;
      background-color: #e2231a;
      color: #fff;
      font-size: 12px;
    }
  }
}
</style>
